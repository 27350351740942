import {
  createStyles,
  ThemeIcon,
  Text,
  Paper,
  rem,
  Title,
  Space,
  Divider,
  Button,
  Grid,
  Center,
  Image,
  UnstyledButton,
} from "@mantine/core";
import { IconAwardFilled } from "@tabler/icons-react";
import { RewardsListHolder } from "./rewardsList";
import { useAppDispatch } from "../../lib/redux/hooks";
import { clearPhone } from "../../lib/redux/reducers/loyalty";
import { useNavigate } from "react-router-dom";
import { useOs } from "@mantine/hooks";

const ICON_SIZE = rem(60);

const useStyles = createStyles((theme) => ({
  card: {
    position: "relative",
    overflow: "visible",
    padding: theme.spacing.xl,
    paddingTop: `calc(${theme.spacing.xl} * 1.5 + ${ICON_SIZE} / 3)`,
  },

  icon: {
    position: "absolute",
    top: `calc(-${ICON_SIZE} / 3)`,
    left: `calc(50% - ${ICON_SIZE} / 2)`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },
}));

function formatDate(date: any) {
  return new Date(date).toLocaleDateString("en-us", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
}

interface CardProps {
  account: any;
  events: any;
}

export function StatsCard({ account, events }: CardProps) {
  const { classes } = useStyles();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const osType = useOs();

  return (
    <Paper radius="md" withBorder className={classes.card} mt={`calc(${ICON_SIZE} / 3)`}>
      <ThemeIcon className={classes.icon} size={ICON_SIZE} radius={ICON_SIZE}>
        <IconAwardFilled size="2rem" stroke={1.5} />
      </ThemeIcon>
      <Text ta="center" fw={700} fz={rem(30)} className={classes.title}>
        Total Points
      </Text>
      <Space h={"md"} />
      <Title
        order={1}
        sx={{ textAlign: "center", paddingBottom: rem(15) }}
        className={classes.highlight}
      >
        {account.balance}
      </Title>
      <Center>
        {osType === "ios" ? (
          <a
            href={`https://squareup.com/apass/loy/download/personalized/${account.id}`}
            target="_blank"
          >
            <Image width="150px" src="../../appleWallet.svg" alt="Apple Wallet" />
          </a>
        ) : (
          <Button
            size="md"
            variant="light"
            disabled
            fullWidth
            onClick={() =>
              navigate("/loyalty/account/history", { state: { account: account, events: events } })
            }
          >
            Loyalty History - Coming Soon!
          </Button>
        )}
      </Center>
      <Space h={"md"} />
      <Divider sx={{ paddingBottom: rem(15) }} />
      <RewardsListHolder account={account} />
      <Grid grow align="center">
        <Grid.Col span={9}>
          <Title order={5}>Member Since {formatDate(account.createdAt)}</Title>
        </Grid.Col>
        <Grid.Col span={1} />
        <Grid.Col span={1}>
          <Button size="sm" color="red" onClick={() => dispatch(clearPhone())}>
            Logout
          </Button>
        </Grid.Col>
      </Grid>
    </Paper>
  );
}
