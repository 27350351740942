import { useQuery } from "react-query"
import axios from "axios"

export const useOrders = (locationID: string | undefined) => {

  let date = new Date();

  date.setDate(date.getDate() - 2)

  const filter =  {

    locationIds: [
      locationID
    ],
    query: {
      filter: {
        stateFilter: {
          states: [
            'OPEN'
          ]
        },
        sourceFilter: {
          sourceNames: [
            'Point of Sale'
          ]
        },
        dateTimeFilter: {
          createdAt: {
            startAt: date.toISOString()
          }
        }
      }
    }
  }

  const searchOrders = () => axios.post(`${process.env.REACT_APP_API_URL}/v1/square/getOrders`, filter, {headers: {
    'Content-Type': 'application/json'
  }}).then((res) => res.data)

  const { isLoading, isError, data: orders } = useQuery({
    queryKey: ['orders'],
    queryFn: () => searchOrders(),
    enabled: true,
    staleTime: 5000,
    refetchInterval: 5000,
    select(data) {
        return data.data.filter((order: any) => order.netAmountDueMoney.amount > 0)
    }
  })

  return {  isLoading, isError, orders }
}