import { createSlice } from "@reduxjs/toolkit";

interface LoyaltyState {
    phone: string,
    expireTime: number
}

const initialState: LoyaltyState = {
    phone: '',
    expireTime: 0
}

export const loyaltySlice = createSlice({
  name: "loyalty",
  initialState,
  reducers: {
    setPhone: (state, action) => {
      state.phone = action.payload;

      let date = new Date()
      date.setTime(date.getTime() + (1*60*60*1000))

      state.expireTime = date.getTime()
    },

    clearPhone: (state) => {
        state.phone = ''
        state.expireTime = 0
    }
  }
});

// Action creators are generated for each case reducer function
export const { setPhone, clearPhone } = loyaltySlice.actions;

export const loyaltyReducer = loyaltySlice.reducer;