import { useQuery } from "react-query"
import axios from "axios"

export const useLoyaltyEvents = (loyaltyID: string) => {
  
  const getLoyaltyEvents = () => axios.get(`${process.env.REACT_APP_API_URL}/v1/square/loyalty/events/${loyaltyID}`).then((res) => res.data)

  const {isLoading, isError, data: events } = useQuery({
    queryKey: ['loyaltyEvents'],
    queryFn: () => getLoyaltyEvents(),
    enabled: loyaltyID !== '',
    staleTime: 5000,
    refetchInterval: 5000,
    select(data) {
      return data.data
    }
  })

  return { isLoading, isError, events }
}
