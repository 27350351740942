import {
  createStyles,
  Text,
  Group,
  rem,
  Stack,
  RingProgress,
  Center,
  Button,
  Grid,
  Loader,
  Title,
  Divider,
  Modal,
} from "@mantine/core";
import { IconStarFilled } from "@tabler/icons-react";
import { useRewards } from "../../hooks/loyalty/useRewards";
import { useNavigate } from "react-router-dom";
import { useDisclosure, useMediaQuery, useSetState } from "@mantine/hooks";
import { useEffect } from "react";
import { LoyaltyAccount, LoyaltyProgramRewardTier } from "square";

const ICON_SIZE = rem(60);

const useStyles = createStyles((theme) => ({
  card: {
    position: "relative",
    overflow: "visible",
    padding: theme.spacing.xl,
    paddingTop: `calc(${theme.spacing.xl} * 1.5 + ${ICON_SIZE} / 3)`,
  },

  icon: {
    position: "absolute",
    top: `calc(-${ICON_SIZE} / 3)`,
    left: `calc(50% - ${ICON_SIZE} / 2)`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },
}));

interface RewardListProps {
  title: string;
  account: LoyaltyAccount | undefined;
  rewards: LoyaltyProgramRewardTier[] | undefined;
}

interface RewardListHolderProps {
  account: LoyaltyAccount | undefined;
}

interface ModalProps {
  isOpen: boolean;
  closeModal: any;
}

function ClaimModal({ isOpen, closeModal }: ModalProps) {
  return (
    <>
      <Modal opened={isOpen} onClose={closeModal} title="Claim Reward">
        <Center>Congrats, you have unlocked an award!</Center>
        <Center>Let your server or bartender know to redeem this reward.</Center>
      </Modal>
    </>
  );
}

function GeneralRewardsList({ title, account, rewards }: RewardListProps) {
  const { classes, theme } = useStyles();
  const small = useMediaQuery(`(max-width: 60em)`);
  const [openedClaim, claim] = useDisclosure(false);

  return (
    <>
      <Title order={1} align="center">
        {title}
      </Title>
      {account &&
        rewards &&
        rewards.map((reward: any) => {
          return (
            <Grid grow align="center" key={reward.id}>
              <Grid.Col span={9}>
                <Group position={small ? "center" : "left"}>
                  <RingProgress
                    size={80}
                    roundCaps
                    thickness={8}
                    sections={[
                      {
                        value: (account.balance! / reward.points) * 100,
                        color:
                          theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
                      },
                    ]}
                    label={
                      <Center>
                        {account.balance! / reward.points > 1 && (
                          <IconStarFilled style={{ color: "gold" }} />
                        )}
                      </Center>
                    }
                  />
                  <div>
                    <Text weight={700} size="xl">
                      {reward.name}
                    </Text>
                    <Text
                      sx={small ? { textAlign: "center" } : {}}
                      color="dimmed"
                      size="xs"
                      transform="uppercase"
                      weight={700}
                    >
                      {account.balance}/{reward.points} points
                    </Text>
                  </div>
                </Group>
              </Grid.Col>
              <Grid.Col sm={8} md={1}>
                <Center>
                  <Button
                    uppercase
                    disabled={account.balance! / reward.points < 1}
                    size="md"
                    onClick={() => claim.open()}
                  >
                    Claim
                  </Button>
                </Center>
              </Grid.Col>
            </Grid>
          );
        })}
      <Divider sx={{ paddingBottom: rem(15) }} />
      <ClaimModal isOpen={openedClaim} closeModal={() => claim.close()} />
    </>
  );
}

export function RewardsListHolder({ account }: RewardListHolderProps) {
  const { classes, theme } = useStyles();

  const { isLoading, isError, rewards: unsortedRewards } = useRewards();

  const [rewards, setRewards]: any = useSetState({
    general: [],
    onetime: [],
    group: {},
  });

  console.log(account);

  useEffect(() => {
    if (unsortedRewards) {
      let genRewards = [];
      let oneRewards = [];
      let groupRewards: any = {};

      for (let reward of unsortedRewards) {
        if (reward.name?.includes("Group: ")) {
          let groupName = reward.name.split("Group:")[1].trim();
          if (groupName in groupRewards) {
            groupRewards[groupName].push(reward);
          } else {
            groupRewards[groupName] = [reward];
          }
        } else {
          genRewards.push(reward);
        }
      }

      setRewards({ general: genRewards, group: groupRewards });
    }
  }, [unsortedRewards]);

  return (
    <>
      <Stack pos="relative">
        {isLoading && <Loader size="xl" style={{ margin: "auto" }} />}
        {/* General Rewards */}
        {!isError && rewards["general"].length > 0 && (
          <>
            <GeneralRewardsList
              account={account}
              rewards={unsortedRewards}
              title={"Loyalty Rewards"}
            />
          </>
        )}
      </Stack>
    </>
  );
}
