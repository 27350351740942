import { useQuery } from "react-query"
import axios from "axios"

export const useCustomer = (custID: string) => {

  const getCustomer = () => axios.get(`${process.env.REACT_APP_API_URL}/v1/square/customers/id/${custID}`).then((res) => res.data)

  const { isLoading, isError, data: customer } = useQuery({
    queryKey: ['customer', custID],
    queryFn: () => getCustomer(),
    enabled: true,
    staleTime: 5000,
    refetchInterval: 5000,
    select(data) {
      return data.data
    }
  })

  return { isLoading, isError, customer }
}
