import {
  createStyles,
  Title,
  Text,
  rem,
  Grid,
  Space,
  Modal,
  Stack,
  List,
  Button,
  Group,
  Divider,
  LoadingOverlay,
} from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { TypeCard } from "./typeCard";
import axios from "axios";
import { useState } from "react";
import { notifications } from "@mantine/notifications";
import { IconExclamationCircle } from "@tabler/icons-react";
import { useCustomer } from "../../hooks/loyalty/useCustomer";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: rem(120),
    paddingBottom: rem(80),

    [theme.fn.smallerThan("sm")]: {
      paddingTop: rem(80),
      paddingBottom: rem(60),
    },
  },

  inner: {
    position: "relative",
    zIndex: 1,
    paddingBottom: rem(25),
  },

  title: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: rem(40),
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },
}));

interface WarnModalProps {
  isOpen: boolean;
  closeHandler: any;
  location?: string;
}

function LocationSelection({ isOpen, closeHandler }: WarnModalProps) {
  return (
    <Modal opened={isOpen} onClose={closeHandler} title="Reward Location" size="auto">
      <Grid gutter="xl" grow justify="center" sx={{ paddingTop: rem(25) }}>
        <Grid.Col span={4}>
          <TypeCard
            title={"Bowland Port Charlotte"}
            image={"../../BLPC.jpg"}
            clickHandler={() => closeHandler("BLPC")}
          />
        </Grid.Col>
      </Grid>
    </Modal>
  );
}

function PrintWarning({ isOpen, closeHandler, location }: WarnModalProps) {
  const { classes, theme } = useStyles();

  const { state } = useLocation();

  const small = useMediaQuery(`(max-width: 480px)`);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { customer } = useCustomer(state.account.id ? state.account.customerId : "");

  const rules = [
    {
      title: "In-Person Redemption:",
      rule: "This loyalty reward can only be redeemed in person at our designated Guest Services Desk at LOCATION.",
    },
    {
      title: "Valid for Current Date Only:",
      rule: "This coupon is valid only on the current date of redemption.",
    },
    {
      title: "Non Food or Beverage Items:",
      rule: "The loyalty reward is applicable exclusively to non-food or non-beverage items.",
    },
    {
      title: "Non-Transferable:",
      rule: "This reward is non-transferable and can only be used by the person to whom it was issued.",
    },
    {
      title: "Partial Redemption:",
      rule: "If the total value of the coupon is not utilized in a single transaction, the remaining balance will be forfeited, and the coupon will be considered fully redeemed.",
    },
  ];

  const redeemReward = () => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/v1/square/loyalty/adjustPoints`, {
        acctID: state.account.id,
        reason: `Reward Redemption - ${state.reward.name}`,
        points: -1 * state.reward.points,
      })
      .then(() => {
        axios
          .post(`${process.env.REACT_APP_API_URL}/v1/square/rewards/printCoupon`, {
            name: `${customer.givenName} ${customer.familyName}`,
            reward: `${state.reward.name}`,
            key: `$${state.reward.definition.fixedDiscountMoney.amount / 100}`,
            loc: `${location}`,
          })
          .then(() => {
            navigate("/loyalty/account/review");

            notifications.show({
              title: "Claim Success!",
              message: "Your reward has been claimed. Please check your updated points balance!",
              color: "green",
              icon: <IconExclamationCircle />,
            });
          })
          .catch(() => {
            console.log("Error Printing");
            setLoading(false);
          });
      })
      .catch(() => {
        console.log("Error Redeeming");
        setLoading(false);
      });
  };

  return (
    <>
      <LoadingOverlay visible={false} overlayBlur={2} loaderProps={{ size: "md" }} />
      <Modal opened={isOpen} onClose={closeHandler} title="Reward Disclaimer" size="auto">
        <Stack align="center" sx={{ paddingBottom: theme.spacing.sm }}>
          <List spacing="xs" size="md" center type="ordered">
            {rules.map((rule) => {
              return (
                <List.Item sx={{ paddingTop: rem(20) }}>
                  <strong>{rule.title}</strong> {rule.rule}
                </List.Item>
              );
            })}
          </List>
        </Stack>
        <Divider size="md" sx={{ padding: rem(10) }} />
        <Group position={small ? "center" : "right"}>
          <Button color="red" radius="md" size="md">
            Decline
          </Button>
          <Button color="teal" radius="md" size="md" onClick={redeemReward}>
            Accept
          </Button>
        </Group>
      </Modal>
    </>
  );
}

interface ClaimTypeProps {
  handler: any;
}

export function ClaimType({ handler }: ClaimTypeProps) {
  const { classes, theme } = useStyles();

  const { state } = useLocation();

  const small = useMediaQuery(`(max-width: 480px)`);

  const [opened, warning] = useDisclosure(false);

  const [locOpened, locationSelect] = useDisclosure(false);

  const [selectedLoc, setLoc] = useState<string>("");

  //<Grid.Col span={4}>
  //<TypeCard title={'Bowling, Laser Tag, & GameZone'} image={'../../Attractions.jpg'} clickHandler={() => warning.open()}/>
  //</Grid.Col>

  return (
    <>
      <Title className={classes.title}>
        Select what activity this reward is being redeemed for.
      </Title>
      <Space h={"xl"} />
      <Title className={classes.title}>
        Reward: {small}
        <Text component="span" className={classes.highlight} inherit>
          {state.reward.name}
        </Text>
      </Title>
      <Grid gutter="xl" grow={small} justify="center" sx={{ paddingTop: rem(25) }}>
        <Grid.Col span={4}>
          <TypeCard title={"Food & Beverage"} image={"../../F&B.jpg"} clickHandler={handler} />
        </Grid.Col>
      </Grid>

      <PrintWarning isOpen={opened} closeHandler={() => warning.close()} location={selectedLoc} />
      <LocationSelection
        isOpen={locOpened}
        closeHandler={(loc: string) => {
          locationSelect.close();
          setLoc(loc);
          warning.open();
        }}
      />
    </>
  );
}
