import { Button, Center, Group, InputBase, Modal, TextInput, Textarea, rem } from '@mantine/core';
import { useForm } from '@mantine/form';
import axios from 'axios';
import { IMaskInput } from 'react-imask';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

interface ModalProps {
  isOpen: boolean
  closeModal: any,
  phone: string
}

export function SupportModal({isOpen, closeModal, phone}: ModalProps) {

    const handleSubmit = (values: any) => {
      axios.post('https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NjUwNTZmMDYzNTA0Mzc1MjZmNTUzNjUxM2Ei_pc', {values: values})
      closeModal()
    }

    const validateEmail = (email: string) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      };

    const form = useForm({
        initialValues: {
          phone: phone,
          email: '',
          name: '',
          details: ''
        },
    
        validate: {
          phone: (value) => isValidPhoneNumber(value) ? null : 'Invalid Phone Number',
          email: (value) => validateEmail(value) && (value.length > 1) ? null : 'Invalid Email',
          name: (value) => (value.length > 1) ? null : 'Invalid Name'
        },
      });


  return (
    <>
      <Modal opened={isOpen} onClose={closeModal} title="Contact Support" size='auto'>
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Group>
            <TextInput placeholder="Your name" label="Full Name" withAsterisk {...form.getInputProps('name')}/>
            <TextInput placeholder="Your email" label="Email" withAsterisk {...form.getInputProps('email')}/>
        </Group>
            <InputBase label="Phone Number" withAsterisk component={IMaskInput} mask="+1 (000) 000-0000" {...form.getInputProps('phone')} />
            <Textarea label="Additional Info" {...form.getInputProps('details')}/>
            <Center sx={{paddingTop: rem(10)}}>
              <Button type="submit">Submit</Button>
            </Center>
          </form>
      </Modal>
    </>
  );
}