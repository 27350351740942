import { useQuery } from "react-query"
import axios from "axios"

export const useOrdersComplete = (locationID: string | undefined) => {

  let runDate = new Date();
  let endDate = new Date();

  //-24hrs datetime
  runDate.setDate(runDate.getDate())
  //current datetime
  endDate.setDate(endDate.getDate() + 1)

  let run = `${runDate.toISOString().split('T')[0]}T06:00:00-04:00`;
  let run2 = `${endDate.toISOString().split('T')[0]}T06:00:00-04:00`;
 
  const filter =  {

    locationIds: [
      locationID
    ],
    query: {
      filter: {
        stateFilter: {
          states: [
            'COMPLETED'
          ]
        },
        dateTimeFilter: {
          closedAt: {
            startAt: run,
            endAt: run2
          }
        },

      },
      sort: {
        sortField: "CLOSED_AT",
        sortOrder: "DESC"
      },
    },
    limit: 100,
    returnEntries: false
  }

  const searchOrders = () => axios.post(`${process.env.REACT_APP_API_URL}/v1/square/getOrders`, filter, {headers: {
    'Content-Type': 'application/json'
  }}).then((res) => res.data)
  
  
  
const findRecentItemSales = (dataJson : any[], item: any) => {
  // Initialize an array to store the matching line items
  const matchingLineItems = [];

  // Loop through the data array
  for (const order of dataJson) {
      const id = order.id;
      const locationId = order.locationId;
      const ticketName = order.ticketName;
      // console.log(ticketName)
      // Check if the order has line items
      if (order.lineItems && Array.isArray(order.lineItems)) {
          for (const lineItem of order.lineItems) {
              const itemName = lineItem.name;
              const grossPrice = lineItem.grossSalesMoney.amount;
              const lineItemID = lineItem.uid

              // Check if the line item name matches 'Boneless Wings'
              if (itemName === item) {
                  // Add the details to the matchingLineItems array
                  matchingLineItems.push({
                      id,
                      locationId,
                      ticketName,
                      itemName,
                      grossPrice,
                      lineItemID
                  });
              }
          }
      }
  }
  // console.log(matchingLineItems)
  // Now, matchingLineItems contains all the matching line item details
  return matchingLineItems;
}

  const { isLoading, isError, data: orders } = useQuery({
    queryKey: ['orders'],
    queryFn: () => searchOrders(),
    enabled: true,
    staleTime: 10000,
    refetchInterval: 10000,
    select(data) {
          // example items: 'Boneless Wings', 'Water Moccasin', 'Kids Grilled Cheese'

          return findRecentItemSales(data.data, 'Holiday Gift Card')
    }
  })

  return {  isLoading, isError, orders }
}