import {
  createStyles,
  ThemeIcon,
  Text,
  Paper,
  rem,
  Title,
  Space,
  Divider,
  Button,
  Grid,
  Center,
  Container,
  LoadingOverlay,
} from "@mantine/core";
import { IconAwardFilled } from "@tabler/icons-react";
import { useLocation, useNavigate } from "react-router-dom";
import { EventCard } from "../../components/loyalty/history/eventCard";

const ICON_SIZE = rem(60);

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: rem(120),
    paddingBottom: rem(80),

    [theme.fn.smallerThan("sm")]: {
      paddingTop: rem(80),
      paddingBottom: rem(60),
    },
  },

  inner: {
    position: "relative",
    zIndex: 1,
    paddingBottom: rem(25),
  },

  header: {
    fontWeight: 800,
    fontSize: rem(25),
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
      textAlign: "center",
    },
  },

  card: {
    position: "relative",
    overflow: "visible",
    padding: theme.spacing.xl,
    paddingTop: `calc(${theme.spacing.xl} * 1.5 + ${ICON_SIZE} / 3)`,
  },

  icon: {
    position: "absolute",
    top: `calc(-${ICON_SIZE} / 3)`,
    left: `calc(50% - ${ICON_SIZE} / 2)`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },
}));

function formatDate(date: any) {
  return new Date(date).toLocaleDateString("en-us", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
}

export function LoyaltyHistory() {
  const { classes } = useStyles();

  const { state } = useLocation();

  const navigate = useNavigate();

  // Use map to loop through the data array and generate React elements
  const eventElements = state.events.map((item: any) => {
    return (
      <div key={item.id}>
        <p>Type: {item.type}</p>
        <p>{formatDate(item.createdAt)}</p>

        {/* Add more properties as needed */}
      </div>
    );
  });

  return (
    <>
      <LoadingOverlay visible={false} overlayBlur={2} loaderProps={{ size: "xl" }} />
      <Container className={classes.wrapper} size={1400}>
        <div className={classes.inner}>
          <Container>
            <Center>
              <Title className={classes.header}>
                Your Loyalty History!{" "}
                {/* <Text component="span" className={classes.highlight} inherit>
              {customer.givenName}{' '}{customer.familyName}
              </Text> */}
              </Title>
            </Center>
            <Space h={"xl"} />
            <Paper radius="md" withBorder className={classes.card} mt={`calc(${ICON_SIZE} / 3)`}>
              <ThemeIcon className={classes.icon} size={ICON_SIZE} radius={ICON_SIZE}>
                <IconAwardFilled size="2rem" stroke={1.5} />
              </ThemeIcon>
              <Text ta="center" fw={700} fz={rem(30)} className={classes.title}>
                Total Points
              </Text>
              <Space h={"md"} />
              <Title
                order={1}
                sx={{ textAlign: "center", paddingBottom: rem(15) }}
                className={classes.highlight}
              >
                {state.account.balance}
              </Title>
              <Center>
                <Button
                  size="md"
                  variant="light"
                  fullWidth
                  onClick={() => navigate("/loyalty/account/review")}
                >
                  Redeem Points
                </Button>
              </Center>
              <Space h={"md"} />
              <Divider sx={{ paddingBottom: rem(15) }} />
              {/* <RewardsList account={account}/> */}
              {/* {eventElements} */}
              <EventCard events={state.events} />
              <Divider sx={{ marginTop: rem(15), paddingBottom: rem(15) }} />
              <Grid grow align="center">
                <Grid.Col span={9}>
                  {/* <Title order={5}>Member Since {formatDate(account.createdAt)}</Title> */}
                </Grid.Col>
                <Grid.Col span={1}>
                  <Button size="sm" color="red" onClick={() => alert("")}>
                    Logout
                  </Button>
                </Grid.Col>
              </Grid>
            </Paper>
          </Container>
        </div>
      </Container>
    </>
  );
}
