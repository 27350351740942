import { useQuery } from "react-query"
import axios from "axios"

export const useClaimOrders = (customerID: string, reward: any) => {

  const getLocationCodes = () => axios.get(`${process.env.REACT_APP_API_URL}/v1/square/locations`).then((res) => res.data)

  const { data: locations } = useQuery({
    queryKey: ['locationCodes'],
    queryFn: () => getLocationCodes(),
    enabled: true,
    staleTime: 5000,
    refetchInterval: 5000,
    select: (data) => ({
      locations: [...data]
      }),
    })

  let date = new Date();

  date.setDate(date.getDate() - 2)

  let filter =  {
    locationIds: locations?.locations.map((loc) => loc.id),
    query: {
      filter: {
        customerFilter: {
            customerIds: [
              customerID
            ]
        },
        stateFilter: {
          states: [
            'OPEN'
          ]
        },
        dateTimeFilter: {
          createdAt: {
            startAt: date.toISOString()
          }
        }
      }
    }
  }

  const searchOrders = () => axios.post(`${process.env.REACT_APP_API_URL}/v1/square/getOrders`, filter, {headers: {
    'Content-Type': 'application/json'
  }}).then((res) => res.data)

  const { isLoading, isError, data: orders } = useQuery({
    queryKey: ['claimorders', locations],
    queryFn: () => searchOrders(),
    enabled: true,
    staleTime: 5000,
    refetchInterval: 5000,
    select(data) {

        let orders = data.data

        if (reward.definition.scope === "ORDER" && orders) {
            orders = orders.filter((order: any) => order.netAmountDueMoney.amount)
        } else if (reward.definition.scope === 'ITEM_VARIATION' && orders) {
            orders = orders.filter((order: any) => {

                let found = false

                order.lineItems.forEach((item: any) => {
                    if (item.catalogObjectId in reward.definition.catalogObjectIds) {
                        found = true
                    }
                })

                return found
            })
        }

        if (!orders) {
          return orders
        }
        orders = orders.filter((order: any) => {

          let valid = true

          if (order.discounts) {
            order.discounts.forEach((discount: any) => {
              if (discount.name.includes('- Reward')) {
                valid = false
              }
            })
          }

          return valid
        })

        return orders

    }
  })

  return {  isLoading, isError, orders }
}