import { createStyles, Title, Text, Container, rem, Grid, LoadingOverlay } from "@mantine/core";
import { useLocation, useParams } from "react-router-dom";
import { TabCard } from "../../components/paytab/tabCard";
import { RedeemModal } from "../../components/giftcards/redeemModalCard";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { Order } from "square";
import { useOrdersComplete } from "../../hooks/giftcards/useOrdersComplete";
import { useRedeemed } from "../../hooks/giftcards/useRedeemed";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: rem(120),
    paddingBottom: rem(80),

    [theme.fn.smallerThan("sm")]: {
      paddingTop: rem(80),
      paddingBottom: rem(60),
    },
  },

  inner: {
    position: "relative",
    zIndex: 1,
    paddingBottom: rem(25),
  },

  title: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: rem(40),
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
      textAlign: "left",
    },
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },
}));

export function PayedCards() {
  const { classes } = useStyles();

  const { location } = useParams();

  const { state } = useLocation();

  const { isLoading, isError, orders } = useOrdersComplete(location);

  const { redeemed } = useRedeemed(location);

  const [openedRedeem, redeem] = useDisclosure(false);

  const [order, setOrder] = useState<Order>();

  const [availableBalance, setavailableBalance] = useState(0);

  const [validOrders, setValid] = useState<any>([]);
  //   new Intl.NumberFormat('en-US',
  //   { style: 'currency', currency: 'USD' }
  // ).format(availableBalance); // '$100.00'

  // const { isLoading: cardLoading, isError: cardError, balance } = useCardBalance(state.card)

  useEffect(() => {
    console.log("HERE!!!!");

    let redeemedCards: any[] = [];

    if (redeemed) {
      redeemedCards = redeemed.map((item: any) => item.itemID);
      console.log(redeemedCards);
      console.log(orders?.filter((order: any) => !redeemedCards.includes(order.lineItemID)));
    }

    setValid(orders?.filter((order: any) => !redeemedCards.includes(order.lineItemID)));
  }, [orders, redeemed]);

  return (
    <>
      <LoadingOverlay visible={isLoading} overlayBlur={2} loaderProps={{ size: "xl" }} />
      <Container className={classes.wrapper} size={1400}>
        <div className={classes.inner}>
          <Title className={classes.title}>
            Select{" "}
            <Text component="span" className={classes.highlight} inherit>
              Gift Card
            </Text>{" "}
            to Activate
          </Title>
        </div>
        <Grid gutter="xl">
          {!isError &&
            validOrders &&
            validOrders.map((order: any) => {
              return (
                <Grid.Col
                  key={order.id}
                  span={4}
                  sx={{ paddingBottom: rem(75) }}
                  onClick={() => {
                    console.log(order);
                    setOrder(order);
                    setavailableBalance(order.grossPrice);
                    redeem.open();
                    // console.log(order.locationId);
                    // console.log(order.grossPrice);
                  }}
                >
                  <TabCard title={order.ticketName} total={order.grossPrice} />
                </Grid.Col>
              );
            })}
        </Grid>
      </Container>
      {order && (
        <RedeemModal
          isOpen={openedRedeem}
          closeModal={() => {
            redeem.close();
            setOrder(undefined);
          }}
          order={order}
          balance={availableBalance}
        />
      )}
    </>
  );
}
