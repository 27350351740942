import {
  Card,
  Image,
  Text,
  Badge,
  Button,
  Group,
  createStyles,
  rem,
  Title,
  Center,
  Space,
} from "@mantine/core";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: rem(120),
    paddingBottom: rem(80),

    [theme.fn.smallerThan("sm")]: {
      paddingTop: rem(80),
      paddingBottom: rem(60),
    },
  },

  inner: {
    position: "relative",
    zIndex: 1,
    paddingBottom: rem(25),
  },

  title: {
    textAlign: "center",
    fontWeight: 700,
    fontSize: rem(25),
    letterSpacing: 0,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },

  card: {
    transition: "box-shadow 150ms ease, transform 100ms ease, background-color 150ms ease",

    "&:hover": {
      boxShadow: theme.shadows.md,
      transform: "scale(1.05)",
      backgroundColor: theme.colors.dark[4],
      cursor: "pointer",
    },
  },
}));

interface TypeProps {
  title: string;
  image: string;
  clickHandler: any;
}

export function TypeCard({ title, image, clickHandler }: TypeProps) {
  const { classes, theme } = useStyles();

  return (
    <Card
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
      onClick={clickHandler}
      className={classes.card}
    >
      <Card.Section>
        <Image src={image} height={250} alt="" />
      </Card.Section>
      <Space h={"lg"} />
      <Center>
        <Title className={classes.title}>{title}</Title>
      </Center>
    </Card>
  );
}
