import {
  Container,
  rem,
  createStyles,
  keyframes,
  Group,
  Stack,
  Title,
  Image,
  Space,
} from "@mantine/core";
import { useScrollIntoView } from "@mantine/hooks";
import LoyaltySignupForm from "../../components/loyalty/signUpForm";

const bounce = keyframes({
  to: { transform: "scale(1.2)" },
});

const useStyles = createStyles((theme) => ({
  label: {
    textAlign: "center",
    fontWeight: 800,
  },

  animateArrow: {
    animation: `${bounce} 1s infinite alternate`,
  },

  title: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: rem(40),
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },
}));

export default function LoyaltySignup() {
  const { classes } = useStyles();

  const { targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 60,
  });

  return (
    <Container
      style={{ backgroundColor: "#273370", maxWidth: "100%", minHeight: "100vh", height: "auto" }}
    >
      <Stack justify="space-around" align="stretch" spacing="xl">
        <Group position="center">
          <Image src="../../HPLogo-Small.png" width="auto" fit="contain"></Image>
          <Image src="../../HeadPinz Rewards.png" width="auto" fit="contain"></Image>
        </Group>
        <Title className={classes.title}>Create a rewards account below to get started.</Title>
      </Stack>
      <Title order={3} style={{ textAlign: "center" }}>
        Be the first to hear about new specials and promotions!
      </Title>
      <Space h="xl" />
      <Container ref={targetRef}>
        <LoyaltySignupForm redirect="/loyalty/infoSlim" />
      </Container>
    </Container>
  );
}
