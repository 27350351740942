import { useState } from 'react';
import { createStyles, Table, Checkbox, Group, Text, rem, Button, Container, Center, LoadingOverlay, Loader, Title, Stack } from '@mantine/core';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { IconExclamationCircle } from '@tabler/icons-react';
import { useAppSelector } from '../../lib/redux/hooks';
import { CustCreateModal } from './createCustModal';

const customLoader = (
  <>
  <Stack align='center'>
  <Loader></Loader>
  <Title>Hang tight! Your account is being created.</Title>
  </Stack>
  </>
);


const useStyles = createStyles((theme) => ({
  rowSelected: {
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2)
        : theme.colors[theme.primaryColor][0],
  },
}));

interface TableSelectionProps {
  data: { name: string, location: string, id: string, phone: string, account: any, points: any }[];
}

export function UserTable({ data: userAccounts }: TableSelectionProps) {

  const { classes, cx } = useStyles();

  const [selection, setSelection] = useState<string[]>([]);

  const [ loading, setLoading ] = useState(false)

  const { phone } = useAppSelector((state) => state.persistedReducer.loyalty)

  const navigate = useNavigate()

  const toggleRow = (id: string) =>
    setSelection((current) =>
      current.includes(id) ? current.filter((item) => item !== id) : [...current, id]
    );
  const toggleAll = () =>
    setSelection((current) => (current.length === userAccounts.length ? [] : userAccounts.map((item) => item.id)));

  const transfer = async () => {

    setLoading(true)

    let found = false;

    await axios.get(`${process.env.REACT_APP_API_URL}/v1/square/loyalty/phone/${phone}`).then((res) => {

      if (res.data.data && userAccounts.length === 0) {

        found = true
        navigate('/loyalty/transfer/start')

        notifications.show({
          title: "Nothing to Transfer",
          message: 'You have already created an account and there is no accounts to transfer.',
          color: 'green',
          icon: <IconExclamationCircle />
        });
      }
    })

    if (found) {
      return
    }

    axios.get(`${process.env.REACT_APP_API_URL}/v1/square/customers/${phone}`).then(async (res) => {

      let cust = res.data.data[0]

      let userData = res.data.data

      console.log(userData)

      if (userData.count > 1) {
        navigate('/loyalty/transfer/start', {state: {support: true}})

        notifications.show({
          title: "Transfer Failed!",
          message: 'You have 2 or more customer accounts with that phone number. Please visit any of our locations for assistance or fill out the support form.',
          color: 'red',
          icon: <IconExclamationCircle />
        });

        return

      } else if (userData.count === 0) {
        
        //THIS NEEDS TO CREATE CUSTOMER AND LOYALTY ACCOUNT
        let created = false

        await CustCreateModal().then((res) => {
          if (res.data) {
            cust = res.data.data
            created = true
          }
        }).catch(() => {
          console.log('Error Creating Account')
        })

        const checkCustomer = async () => {
          let count = await axios.get(`${process.env.REACT_APP_API_URL}/v1/square/customers/${phone}`).then(async (res) => {
            return res.data.data.count
          }).catch(() => {return 0})
          return count
        }

        function sleep(ms: any) {
          return new Promise(resolve => setTimeout(resolve, ms));
        }
        

        while (await checkCustomer() === 0) {
          await sleep(5000)
        }



      }

      let loyaltyID;

      await axios.get(`${process.env.REACT_APP_API_URL}/v1/square/loyalty/phone/${phone}`).then(async (res) => {
        

        if (!res.data.data) {

          await axios.post(`${process.env.REACT_APP_API_URL}/v1/square/loyalty/create`, {
            custID: cust.id,
            phone: `${cust.phoneNumber}`
          }).then((res) => {
            loyaltyID = res.data.data.id
          })
        } else {
          loyaltyID = res.data.data[0].id
        }
      })

      //ADD POINTS TO ACCOUNT!!!

      if (userAccounts.length === 0) {

        navigate('/loyalty/account/review')

        notifications.show({
          title: "Transfer Success!",
          message: 'Welcome to HeadPinz Rewards! You can now login with your phone number.',
          color: 'green',
          icon: <IconExclamationCircle />
        });

        return
      }

      let error = false

      for (let ID in selection) {

        let userAcc = userAccounts.find((user) => user.id === selection[ID])!.account

        axios.post(`${process.env.REACT_APP_API_URL}/v1/conqx/transfer`, {
          userID: userAcc.IDCustomer,
          centerID: userAcc.IDCenter,
          prevNotes: userAcc.Notes,
          serverID: selection[ID].split('-')[2],
          acctID: loyaltyID
        }).then(() => {
          console.log(`Transferred Account: ${userAcc.IDCustomer}`)
        }).catch(() => {
          error = true
        })
      }

      if (error) {
        navigate('/loyalty/transfer/start', {state: {support: true}})

        notifications.show({
          title: "Transfer Failed!",
          message: 'An error occured with transferring your account. Please try again later.',
          color: 'red',
          icon: <IconExclamationCircle />
        });
      }

      navigate('/loyalty/account/review')

      notifications.show({
        title: "Transfer Success!",
        message: `Welcome to HeadPinz Rewards! You can now login with your phone number!`,
        color: 'green',
        icon: <IconExclamationCircle />
      });

    })
  }

  const rows = userAccounts.map((item) => {
    const selected = selection.includes(item.id);
    return (
      <tr key={item.id} className={cx({ [classes.rowSelected]: selected })}>
        <td>
          <Checkbox
            checked={selection.includes(item.id)}
            onChange={() => toggleRow(item.id)}
            transitionDuration={0}
          />
        </td>
        <td>
          <Group spacing="sm">
            <Text size="sm" weight={500}>
              {item.name}
            </Text>
          </Group>
        </td>
        <td>{item.location}</td>
        <td>{item.points}</td>
      </tr>
    );
  });

  return (
    <>
      <LoadingOverlay loader={customLoader} visible={loading} title='Please wait!' overlayBlur={2} />
      {userAccounts.length >= 1 && <Table verticalSpacing="sm">
        <thead>
          <tr>
            <th style={{ width: rem(40) }}>
              <Checkbox
                onChange={toggleAll}
                checked={selection.length === userAccounts.length}
                indeterminate={selection.length > 0 && selection.length !== userAccounts.length}
                transitionDuration={0}
              />
            </th>
            <th>Name</th>
            <th>Location</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table> }
      <Container sx={{paddingTop: rem(15)}}>
        <Center>
          <Button color="teal" size="md" disabled={(userAccounts.length > 0 && selection.length < 1) || loading} onClick={() => transfer()}>{userAccounts.length > 0 ? 'Begin Transfer' : 'Create Loyalty Account'}</Button>
        </Center>
      </Container>
    </>
  );
}