import { Modal, Image, createStyles, rem, Container, Badge, Group, Text, Stack, SegmentedControl, Accordion, NumberInput, Button, Center } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Order } from 'square';
import { TabInfo } from './tabInfo';
import { IconArrowBadgeRight, IconBrandCashapp } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { notifications } from '@mantine/notifications';

const useStyles = createStyles((theme) => ({

  imageSection: {
    padding: theme.spacing.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    letterSpacing: rem(-0.25),
    textTransform: 'uppercase',
  },

  section: {
    padding: theme.spacing.md,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  icon: {
    marginRight: rem(5),
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
  },

  itemList: {
    width: 'fit-content', 
    margin: 'auto', 
    borderBottom: `${rem(1)} solid`,
    paddingBottom: `${theme.spacing.xs}`
  }

}));

interface ModalProps {
  isOpen: boolean
  closeModal: any
  order: Order
  cardNumber: any
  balance: number
  location: string
}

function submitPayment(order: any, paymentAmount: number, cardNumber: string, location: string) {
  let body = {
    order: order,
    paymentAmount: paymentAmount,
    card: cardNumber
  }

  axios.post(`${process.env.REACT_APP_API_URL}/v1/${location}/submitPayment`, body, {headers: {
    'Content-Type': 'application/json'
  }}).then((res: any) => {
    console.log(res)

    if (res.data.success) {
        
      notifications.show({
        autoClose: 10000,
        title: "Payment Successful!",
        message: 'Please check the order for correct amount and complete payment on Square.',
        color: 'green',
        icon: <IconBrandCashapp />
      });

    } else {

      notifications.show({
        autoClose: 10000,
        title: "Payment Failed!",
        message: 'Please try again or call Ext.8000!',
        color: 'red',
        icon: <IconBrandCashapp />
      });
    }
  })
}

function formatMoney(amt: number) {
  return Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(amt)
}

export function RedeemModal({isOpen, closeModal, order, cardNumber, balance, location}: ModalProps) {

  const navigate = useNavigate()
  
  const [ orderTotal, setOrderTotal ] = useState(Number(order.netAmountDueMoney?.amount)*0.01)

  const { classes, theme } = useStyles();

  const [value, setValue] = useState<string | null>(null);

  const [ appliedMoney, setMoney ] = useState<number | ''>(Math.min(balance, orderTotal))

  useEffect(() => {
    if (order.netAmountDueMoney?.amount && Number(order.netAmountDueMoney?.amount)*0.01 !== orderTotal) {
      setOrderTotal(Number(order.netAmountDueMoney?.amount)*0.01)
    }
  }, [order.netAmountDueMoney?.amount, orderTotal])

  useEffect(() => {
    if (value === '100') {
      setMoney(Math.min(balance, orderTotal))
    }
  }, [value, balance, orderTotal])

  return (
    <>
      <Modal opened={isOpen} onClose={closeModal} title="Redeem Gift Card">
        <Stack align='center' sx={{paddingBottom: theme.spacing.sm}}>
          <Image maw={240} mx="auto" radius="md" src="../../ptf.png" alt="Random image" />
          <Group position="center" mt="md" className={classes.section}>
            <div>
              <Text fw={500} size='xl'>Anywhere Cash Balance:</Text>
            </div>
            <Badge variant="outline" size='xl'>{formatMoney(balance)}</Badge>
          </Group>
          <SegmentedControl size='lg' radius="xl" color="blue"
            data={[
              { value: '100', label: '100%' },
              { value: 'other', label: 'Other' },
            ]} onChange={(data) => {
              setValue(data)
            }}
          />
          <Accordion variant="separated" defaultValue="" unstyled value={value} onChange={setValue}>
            <Accordion.Item value="other">
              <Accordion.Panel>
                <NumberInput
                  value={appliedMoney} onChange={setMoney} hideControls label="Gift Card Amount" defaultValue={Math.min(balance, orderTotal)}
                  precision={2} min={0} max={Math.min(balance, orderTotal)}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  pattern="[0-9]*"
                  formatter={(value) =>
                    !Number.isNaN(parseFloat(value))
                      ? `$ ${value}`.replace(/^(\d{3})*$/g, ',')
                      : '$ '
                  }/>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Stack>
        <Container className={classes.section}>
          <TabInfo order={order} appliedMoney={Number(appliedMoney)} balance={balance} orderTotal={orderTotal}/>
        </Container>
        <Center>
          <Button color="teal" radius="md" size="md" rightIcon={<IconArrowBadgeRight/>} onClick={async () => {
            submitPayment(order, Number(appliedMoney)*100, cardNumber, location)
            navigate(`/paytab/${order.locationId}/reader`)
            }}>Submit</Button>
        </Center>
      </Modal>
    </>
  );
}