import { Button, Center, Group, InputBase, Modal, Stack, TextInput, rem } from "@mantine/core";
import { useForm } from "@mantine/form";
import axios from "axios";
import { create } from "react-modal-promise";
import { useAppSelector } from "../../lib/redux/hooks";
import { IMaskInput } from "react-imask";

interface ModalProps {
  isOpen: boolean;
  onResolve: (value: any) => void;
  onReject: (value: any) => void;
}

const CreateCustModal = ({ isOpen, onResolve, onReject }: ModalProps) => {
  const handleSubmit = (values: any) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/v1/square/customers/create`, {
        firstname: values.firstname,
        lastname: values.lastname,
        phone: phone,
        birthday: values.birthday.replace("/", "-"),
        email: values.email,
      })
      .then((res) => {
        onResolve(res);
      })
      .catch((e) => {
        onReject(false);
      });
  };

  const { phone } = useAppSelector((state) => state.persistedReducer.loyalty);

  const form = useForm({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      birthday: "",
    },

    validate: {
      firstname: (value) => (value.length >= 1 ? null : "Invalid Name"),
      lastname: (value) => (value.length >= 1 ? null : "Invalid Name"),
      email: (value) =>
        value.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
          ? null
          : "Invalid Email",
      birthday: (value) =>
        value.match(/(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])/) ? null : "Invalid Date",
    },
  });

  return (
    <Modal
      opened={isOpen}
      onClose={() => onResolve(false)}
      title="Create Account"
      size="auto"
      centered
    >
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Stack align="center">
          <Group>
            <TextInput
              placeholder="Your name"
              label="First Name"
              withAsterisk
              {...form.getInputProps("firstname")}
            />
            <TextInput
              placeholder="Your name"
              label="Last Name"
              withAsterisk
              {...form.getInputProps("lastname")}
            />
          </Group>
          <Group>
            <TextInput
              placeholder="Your email"
              label="Email"
              withAsterisk
              {...form.getInputProps("email")}
            />
            <InputBase
              label="Birthday"
              placeholder="Your birthday MM/DD"
              inputMode="numeric"
              component={IMaskInput}
              mask="00/00"
              {...form.getInputProps("birthday")}
            />
          </Group>
        </Stack>

        <Center sx={{ paddingTop: rem(10) }}>
          <Button type="submit">Submit</Button>
        </Center>
      </form>
    </Modal>
  );
};

export const CustCreateModal = create(CreateCustModal);
