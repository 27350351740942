import { Button, Center, Flex, Group, Modal, PinInput, Title, Text, rem, NumberInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconLock } from "@tabler/icons-react";
import axios from "axios";
import { useAppDispatch } from "../../lib/redux/hooks";
import { setPhone } from "../../lib/redux/reducers/loyalty";
import { useNavigate } from "react-router-dom";

interface ModalProps {
  isOpen: boolean
  closeModal: any
  phoneNumber: string
  route: string
}

export function PinModal({isOpen, closeModal, phoneNumber, route}: ModalProps) {

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const form = useForm({
    initialValues: {
      otp: ''
    },

    validate: {
      otp: (value: any) => value.length === 6 ? null : 'Invalid Pin',
    }
  });

  const verifyOTP = async (code: string) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/v1/verifyOTP`, {phoneNumber: phoneNumber, code: code}, {headers: {
      'Content-Type': 'application/json'
    }}).then(() => {
      dispatch(setPhone(phoneNumber))
      navigate(route)
    })
  };

  const checkLength = () => {
    if (form.getInputProps('otp').value.length === 5) {
      verifyOTP(form.getInputProps('otp').value)
    }
  }

  return (
    <Modal opened={isOpen} onClose={closeModal} 
    title={<Flex align="center" gap="xs"><IconLock/>Verification Pin</Flex>} >
         <Group position="center">
         <Text sx={{textAlign: 'center'}}>We have sent a one-time code to your phone ending in ***-***-{phoneNumber.substring(7)}.</Text>
          <form onSubmit={form.onSubmit((values) => verifyOTP(values.otp))} onChange={() => {checkLength()}}>
            <PinInput oneTimeCode inputMode="numeric" length={6} {...form.getInputProps('otp')}/>
            <Center sx={{paddingTop: rem(10)}}>
              <Button type="submit">Submit</Button>
            </Center>

          </form>
        </Group>
    </Modal>
  )
}
