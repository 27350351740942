import { createStyles, Text, Card, Group, rem } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  card: {
    transition: 'box-shadow 150ms ease, transform 100ms ease, background-color 150ms ease',

    '&:hover': {
      boxShadow: theme.shadows.md,
      transform: 'scale(1.05)',
      backgroundColor: theme.colors.dark[4],
      cursor: 'pointer'
    },
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    lineHeight: 1,
  },

  lead: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    fontSize: rem(22),
    lineHeight: 1,
  },

  inner: {
    display: 'flex',

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },
}));

interface TabCardProps {
  title: any;
  total: any;
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export function TabCard({ title, total }: TabCardProps) {
  const { classes } = useStyles();

  return (
    <Card withBorder p="xl" radius="md" className={classes.card}>
      <Group position="center" grow>
          <Text ta="center" fz="xl" className={classes.label}>
            {title ? title : 'No Name'}
          </Text>
          <Text ta="center" fz="xl" className={classes.label}>
            {formatter.format(total*0.01)}
          </Text>
        </Group>
    </Card>
  );
}