import { createStyles, Text, Card, Group, rem, Button, Divider, LoadingOverlay, Modal, Stack } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconExclamationCircle } from '@tabler/icons-react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  card: {
    transition: 'box-shadow 150ms ease, transform 100ms ease, background-color 150ms ease',

    '&:hover': {
      boxShadow: theme.shadows.md,
      transform: 'scale(1.05)',
      backgroundColor: theme.colors.dark[4],
      cursor: 'pointer'
    },
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    lineHeight: 1,
  },

  lead: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    fontSize: rem(22),
    lineHeight: 1,
  },

  inner: {
    display: 'flex',

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },
}));

interface TabCardProps {
  title: any;
  total: any;
  order: any;
  loadHandler: any;
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

interface WarnModalProps {
  isOpen: boolean,
  closeHandler: any,
  loadHandler: any,
  order: any
}

async function redeemReward(state: any, order: any, loadHandler: any) {

    loadHandler(true)
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/v1/square/rewards/claim`, {
      order: order,
      reward: state.reward,
      account: state.account
      }).then(() => {
        notifications.show({
          title: "Claim Success!",
          message: 'Your reward has been claimed. Please check your updated points balance!',
          color: 'green',
          icon: <IconExclamationCircle />
        });
      }).catch(() => {
        console.log('Error applying discount!')
        loadHandler(false)
        notifications.show({
          title: "Claim Failure!",
          message: 'Your reward has not been claimed. Please check your points balance!',
          color: 'red',
          icon: <IconExclamationCircle />
        })
      })
    } catch {
      console.log('Error Redeeming')
      loadHandler(false)
      notifications.show({
        title: "Claim Failure!",
        message: 'Your reward has not been claimed. Please check your points balance!',
        color: 'red',
        icon: <IconExclamationCircle />
      })
    }
}

function PrintWarning({isOpen, closeHandler, order, loadHandler}: WarnModalProps) {
  const { theme } = useStyles();

  const { state } = useLocation();

  const small = useMediaQuery(`(max-width: 480px)`);

  const navigate = useNavigate();

  return (
    <>
    <LoadingOverlay visible={false} overlayBlur={2} loaderProps={{size: 'md'}} />
    <Modal opened={isOpen} onClose={closeHandler} title="Partial Reward Disclaimer" size="auto">
      <Stack align='center' sx={{paddingBottom: theme.spacing.sm}}>
        <Text>If the total value (${state.reward.definition.fixedDiscountMoney.amount/100}) of the coupon is not utilized in a single transaction, the remaining balance will be forfeited, and the coupon will be considered fully redeemed.</Text>
      </Stack>
      <Divider size="md" sx={{padding: rem(10)}}/>
      <Group position={small ? 'center' : 'right'}>
        <Button color="red" radius="md" size="md">Decline</Button>
        <Button color="teal" radius="md" size="md" onClick={async () => {await redeemReward(state, order, loadHandler); navigate('/loyalty/account/review');}}>Accept</Button>
      </Group>
    </Modal>
    </>
  )
}


export function TabCard({ title, total, order, loadHandler }: TabCardProps) {
  const { classes } = useStyles();

  const { state } = useLocation();

  const [opened, warning] = useDisclosure(false);
  
  const navigate = useNavigate();

  return (
    <>
    <Card withBorder p="xl" radius="md" className={classes.card} onClick={async () => {
      console.log(total/100, state.reward.definition.fixedDiscountMoney.amount/100)
      if (total/100 >= state.reward.definition.fixedDiscountMoney.amount/100) {
        await redeemReward(state, order, loadHandler)

        navigate('/loyalty/account/review')
      } else {
        warning.open()
      }
    }}>
      <Group position="center" grow>
          <Text ta="center" fz="xl" className={classes.label}>
            {title ? title : 'No Name'}
          </Text>
          <Text ta="center" fz="xl" className={classes.label}>
            {formatter.format(total*0.01)}
          </Text>
        </Group>
    </Card>
    <PrintWarning isOpen={opened} closeHandler={() => warning.close()} order={order} loadHandler={loadHandler}/>
    </>
  );
}