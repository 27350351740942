import {
  Stack,
  TextInput,
  Checkbox,
  Button,
  InputBase,
  rem,
  createStyles,
  keyframes,
  LoadingOverlay,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { IMaskInput } from "react-imask";
import { parsePhoneNumber } from "react-phone-number-input/input";
import axios from "axios";
import { useAppSelector } from "../../lib/redux/hooks";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconExclamationCircle } from "@tabler/icons-react";

const bounce = keyframes({
  to: { transform: "scale(1.2)" },
});

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: rem(120),
    paddingBottom: rem(80),

    [theme.fn.smallerThan("sm")]: {
      paddingTop: rem(80),
      paddingBottom: rem(60),
    },
  },

  label: {
    textAlign: "center",
    fontWeight: 800,
  },

  animateArrow: {
    animation: `${bounce} 1s infinite alternate`,
  },

  title: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: rem(40),
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },
}));

interface FormProps {
  redirect?: string;
}

export default function LoyaltySignupForm({ redirect }: FormProps) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/v1/square/customers/${values.phone}`)
        .then(async (res) => {
          let userData = res.data.data;

          if (userData && userData.count != 0) {
            await axios
              .get(`${process.env.REACT_APP_API_URL}/v1/square/loyalty/phone/${values.phone}`)
              .then(async (res2) => {
                if (!res2.data.data) {
                  await axios.post(`${process.env.REACT_APP_API_URL}/v1/square/loyalty/create`, {
                    custID: res.data.data[0].id,
                    phone: `+1${res.data.data[0].phoneNumber}`,
                  });
                  notifications.show({
                    autoClose: 10000,
                    title: "Account Created!",
                    message: "Account may take up to 5 minutes to be activated.",
                    color: "green",
                    icon: <IconCheck />,
                  });
                } else {
                  notifications.show({
                    autoClose: 10000,
                    title: "Duplicate Account",
                    message: "Account already exists with that phone number.",
                    color: "yellow",
                    icon: <IconCheck />,
                  });
                }
              });
          } else {
            await axios
              .post(`${process.env.REACT_APP_API_URL}/v1/square/customers/create`, {
                firstname: values.firstName,
                lastname: values.lastName,
                email: values.email,
                phone: values.phone,
                birthday: values.birthday.replace("/", "-"),
              })
              .then(async (res) => {
                await axios
                  .get(`${process.env.REACT_APP_API_URL}/v1/square/loyalty/phone/${values.phone}`)
                  .then(async (res2) => {
                    if (!res2.data.data) {
                      await axios.post(
                        `${process.env.REACT_APP_API_URL}/v1/square/loyalty/create`,
                        {
                          custID: res.data.data.id,
                          phone: `+1${res.data.data.phoneNumber}`,
                        }
                      );
                      notifications.show({
                        autoClose: 10000,
                        title: "Account Created!",
                        message: "Account may take up to 5 minutes to be activated.",
                        color: "green",
                        icon: <IconCheck />,
                      });
                    }
                  });
              })
              .catch((e) => {
                notifications.show({
                  autoClose: 10000,
                  title: "UhOh!",
                  message: "Something went wrong. Please try again later.",
                  color: "red",
                  icon: <IconExclamationCircle />,
                });
                console.log(e);
              });
          }

          setLoading(false);
          form.reset();
          if (redirect) {
            navigate(redirect);
          }
        });
    } catch (e) {
      notifications.show({
        autoClose: 10000,
        title: "UhOh!",
        message: "Something went wrong. Please try again later.",
        color: "red",
        icon: <IconExclamationCircle />,
      });
      setLoading(false);
      form.reset();
      if (redirect) {
        navigate(redirect);
      }
    }
  };

  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      birthday: "",
      phone: null,
      opt: true,
    },

    validate: {
      firstName: (value) => (value.length >= 1 ? null : "Invalid Name"),
      lastName: (value) => (value.length >= 1 ? null : "Invalid Name"),
      email: (value) =>
        value.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
          ? null
          : "Invalid Email",
      birthday: (value) => {
        if (!value) {
          return null;
        }

        if (value.match(/(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])/)) {
          return null;
        }

        return "Invalid Date";
      },
      phone: (value) => (parsePhoneNumber(value!)?.isValid() ? null : "Invalid Phone Number"),
    },
  });

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: "xl" }} />
      <form onSubmit={form.onSubmit(handleSubmit, console.log)}>
        <Stack>
          <TextInput
            required
            label="First Name"
            placeholder="Your first name"
            radius="md"
            classNames={{ label: classes.label }}
            {...form.getInputProps("firstName")}
          />

          <TextInput
            required
            label="Last Name"
            placeholder="Your last name"
            radius="md"
            classNames={{ label: classes.label }}
            {...form.getInputProps("lastName")}
          />

          <TextInput
            required
            label="Email"
            placeholder="hello@headpinz.com"
            error={form.errors.email && "Invalid email"}
            radius="md"
            classNames={{ label: classes.label }}
            {...form.getInputProps("email")}
          />

          <InputBase
            required
            label="Phone Number"
            inputMode="numeric"
            radius="md"
            component={IMaskInput}
            mask="+1 (000) 000-0000"
            classNames={{ label: classes.label }}
            {...form.getInputProps("phone")}
          />

          <InputBase
            label="Birthday"
            placeholder="Your birthday MM/DD"
            inputMode="numeric"
            component={IMaskInput}
            mask="00/00"
            {...form.getInputProps("birthday")}
          />

          <Checkbox
            required
            label="You agree to receive automated SMS and email marketing."
            {...form.getInputProps("opt")}
          />

          <Button type="submit" radius="xl">
            Join HeadPinz Rewards
          </Button>
        </Stack>
      </form>
    </>
  );
}
