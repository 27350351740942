import { createStyles, Title, Text, Container, rem, Alert, Button, Center } from "@mantine/core";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: rem(120),
    paddingBottom: rem(80),

    [theme.fn.smallerThan("sm")]: {
      paddingTop: rem(80),
      paddingBottom: rem(60),
    },
  },

  inner: {
    position: "relative",
    zIndex: 1,
    paddingBottom: rem(25),
  },

  title: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: rem(40),
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
      textAlign: "left",
    },
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },
}));

export function CompletedActivation() {
  const { classes } = useStyles();

  const { state } = useLocation();

  const navigate = useNavigate();

  return (
    <>
      <Container className={classes.wrapper} size={1400}>
        <div className={classes.inner}>
          <Title className={classes.title}>
            Activation{" "}
            <Text component="span" className={classes.highlight} inherit>
              Complete
            </Text>{" "}
          </Title>
        </div>
        <Alert
          title={state.success ? "Card Loaded" : "Card Failed"}
          color={state.success ? "green" : "red"}
        >
          Card: {state.card}
        </Alert>
      </Container>
      <Center>
        <Button
          mx="auto"
          color="red"
          radius="md"
          size="xl"
          onClick={async () => {
            navigate(`/giftcard/${state.location}/redeem`);
          }}
        >
          Return Home
        </Button>
      </Center>
    </>
  );
}
