import { Modal, Image, createStyles, rem, Container, Badge, Group, Text, Stack, Accordion, Button, Center } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Order } from 'square';
// import { TabInfo } from './tabInfo';
import { useDisclosure, useOs } from '@mantine/hooks';
import { ReaderModal } from './scanModalCard';
import { SwipeModal } from './swipeModalCard';

const useStyles = createStyles((theme) => ({

  imageSection: {
    padding: theme.spacing.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    letterSpacing: rem(-0.25),
    textTransform: 'uppercase',
  },

  section: {
    padding: theme.spacing.md,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  icon: {
    marginRight: rem(5),
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
  },

  itemList: {
    width: 'fit-content', 
    margin: 'auto', 
    borderBottom: `${rem(1)} solid`,
    paddingBottom: `${theme.spacing.xs}`
  }

}));

interface ModalProps {
  isOpen: boolean
  closeModal: any
  order: Order
  balance: number
}

function formatMoney(amt: number) {
  return Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(amt)
}

export function RedeemModal({isOpen, closeModal, order, balance}: ModalProps) {
  
  const [ orderTotal, setOrderTotal ] = useState(Number(order.netAmountDueMoney?.amount)*0.01)

  const { classes, theme } = useStyles();

  const [value, setValue] = useState<string | null>(null);

  // const [ appliedMoney, setMoney ] = useState<number | ''>(Math.min(balance, orderTotal))

  const [openedScan, scan] = useDisclosure(false);

  const [openedSwipe, swipe] = useDisclosure(false);

  const os = useOs();
  

  useEffect(() => {
    if (order.netAmountDueMoney?.amount && Number(order.netAmountDueMoney?.amount)*0.01 !== orderTotal) {
      setOrderTotal(Number(order.netAmountDueMoney?.amount)*0.01)
    }
  }, [order.netAmountDueMoney?.amount, orderTotal])

  // useEffect(() => {
  //   if (value === '100') {
  //     setMoney(Math.min(balance, orderTotal))
  //   }
  // }, [value, balance, orderTotal])

  return (
    <>
      <Modal opened={isOpen} onClose={closeModal} title="Activate Gift Card">
        <Stack align='center' sx={{paddingBottom: theme.spacing.sm}}>
          <Image maw={240} mx="auto" radius="md" src="../../ptf.png" alt="Random image" />
          <Group position="center" mt="md" className={classes.section}>
            <div>
              <Text fw={500} size='xl'>Tab: {order.ticketName}</Text>
            </div>
          </Group>
          <Group position="center" mt="md" className={classes.section}>
          <div>
              <Text fw={500} size='xl'>Balance: </Text>
            </div>
            <Badge variant="outline" size='xl'>{formatMoney(balance/100)}</Badge>
          </Group>
          <Accordion variant="separated" defaultValue="" unstyled value={value} onChange={setValue}>
            <Accordion.Item value="other">
              {/* <Accordion.Panel>
                <NumberInput
                  value={appliedMoney} onChange={setMoney} hideControls label="Gift Card Amount" defaultValue={Math.min(balance, orderTotal)}
                  precision={2} min={0} max={Math.min(balance, orderTotal)}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  pattern="[0-9]*"
                  formatter={(value) =>
                    !Number.isNaN(parseFloat(value))
                      ? `$ ${value}`.replace(/^(\d{3})*$/g, ',')
                      : '$ '
                  }/>
              </Accordion.Panel> */}
            </Accordion.Item>
          </Accordion>
        </Stack>
        <Container className={classes.section}>
        </Container>
        <Center>
        <div>
              <Text fw={500} size='xl' mb="md">Select Gift Card Option</Text>
        </div>
        </Center>
        <Center>
          {/* <Button mx="auto" color="blue" radius="md" size="xl" rightIcon={<IconCamera/>} onClick={async () => {
            // submitPayment(order, Number(appliedMoney)*100, cardNumber, location)
            // navigate(`/paytab/${order.locationId}/reader`)
            }}>Scan</Button>
          <Button mx="auto" color="teal" radius="md" size="xl" rightIcon={<IconSwipe/>} onClick={async () => {
            // submitPayment(order, Number(appliedMoney)*100, cardNumber, location)
            // navigate(`/paytab/${order.locationId}/reader`)
            }}>Swipe</Button> */}
            {os === 'windows' &&
            <Button mx="auto" size="lg" variant="default" color="gray" onClick={swipe.open}>
              Swipe Card
            </Button>}
            {os &&
            <Button mx="auto" size="lg" onClick={scan.open}>
              Scan Card
            </Button>}
          <ReaderModal isOpen={openedScan} closeModal={() => scan.close()} order={order}/>
          <SwipeModal isOpen={openedSwipe} closeModal={() => swipe.close()} order={order}/>
        </Center>
      </Modal>
    </>
  );
}