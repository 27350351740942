import { useQuery } from "react-query"
import axios from "axios"

export const useCardBalance = (cardNumber: string) => {

  const getBalance = () => axios.get(`${process.env.REACT_APP_API_URL}/v1/intercard/getBalance/${cardNumber}`).then((res) => res.data)

  const { isLoading, isError, data: balance } = useQuery({
    queryKey: ['balance'],
    queryFn: () => getBalance(),
    enabled: true,
    staleTime: 5000,
    refetchInterval: 5000,
    select(data) {
      return data.data.cash
    }
  })

  return { isLoading, isError, balance }
}
