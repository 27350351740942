import { createStyles, Title, Text, Button, Container, rem, Image, Group, Center } from '@mantine/core';
import { ReaderModal } from '../../components/paytab/scanModal';
import { useDisclosure, useOs } from '@mantine/hooks';
import { SwipeModal } from '../../components/paytab/swipeModal';

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    paddingTop: rem(120),
    paddingBottom: rem(80),

    [theme.fn.smallerThan('sm')]: {
      paddingTop: rem(80),
      paddingBottom: rem(60),
    },
  },

  inner: {
    position: 'relative',
    zIndex: 1,
  },

  title: {
    textAlign: 'center',
    fontWeight: 800,
    fontSize: rem(40),
    letterSpacing: -1,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan('xs')]: {
      fontSize: rem(28),
      textAlign: 'left',
    },
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6],
  },

  description: {
    textAlign: 'center',

    [theme.fn.smallerThan('xs')]: {
      textAlign: 'left',
      fontSize: theme.fontSizes.md,
    },
  },

  controls: {
    marginTop: theme.spacing.lg,
    display: 'flex',
    justifyContent: 'center',

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  control: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing.md,
    },

    [theme.fn.smallerThan('xs')]: {
      height: rem(42),
      fontSize: theme.fontSizes.md,

      '&:not(:first-of-type)': {
        marginTop: theme.spacing.md,
        marginLeft: 0,
      },
    },
  },
}));

export function ModalControl() {
  const { classes } = useStyles();

  const [openedScan, scan] = useDisclosure(false);

  const [openedSwipe, swipe] = useDisclosure(false);

  const os = useOs();

  return (
    <>
      <Container className={classes.wrapper} size={1400}>

        <div className={classes.inner}>
          <Title className={classes.title}>
            Redeem a{' '}
            <Text component="span" className={classes.highlight} inherit>
              Passport to Fun
            </Text>{' '}
          </Title>

          <Container p={0} size={600}>
            <Text size="lg" color="dimmed" className={classes.description}>
              Please select to swipe or scan a Passport to Fun to get started.
            </Text>
          </Container>
          <div className={classes.controls}>
            {os === 'windows' &&
            <Button className={classes.control} size="lg" variant="default" color="gray" onClick={swipe.open}>
              Swipe Card
            </Button>}
            {os &&
            <Button className={classes.control} size="lg" onClick={scan.open}>
              Scan Card
            </Button>}
          </div>
        </div>
      </Container>
      <Container>
        <Center>
          
          <Group position='center' style={{width: '50%'}} grow>
            <Image maw={240} mx="auto" radius="md" src="../../BLlogo.png" alt="Random image" />
            <Image maw={240} mx="auto" radius="md" src="../../HPlogo.png" alt="Random image" />
          </Group>
        </Center>
      </Container>
      <ReaderModal isOpen={openedScan} closeModal={() => scan.close()}/>
      <SwipeModal isOpen={openedSwipe} closeModal={() => swipe.close()}/>
    </>
  );
}