import { Modal } from '@mantine/core';
import { useEffect } from 'react';
import CardSwipe from 'react-card-swipe'
import { useNavigate, useParams } from 'react-router-dom';

interface ModalProps {
  isOpen: boolean
  closeModal: any
}

export function SwipeModal({isOpen, closeModal}: ModalProps) {

    const navigate = useNavigate()

    const { location } = useParams()

    const handleSwipeSuccess = (swipeData: any) => {
      navigate(`/paytab/redeem`, {state: {card: swipeData.line2.split('=')[1], location: location}})
    }

    useEffect(() => {
      CardSwipe.init({
        success: handleSwipeSuccess,
        debug: false,
        interdigitTimeout: 1000
      })
    }, [isOpen])

  return (
    <>
      <Modal opened={isOpen} onClose={closeModal} title="Swipe Card">
      </Modal>
    </>
  );
}