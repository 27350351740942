import { Center, Modal, Select, Stack } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconBug } from '@tabler/icons-react';
import { QrScanner } from '@yudiel/react-qr-scanner';
import axios, { AxiosError, isAxiosError } from 'axios';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface ModalProps {
  isOpen: boolean
  closeModal: any
}

const listDevices = async () => {
  const devices = await navigator.mediaDevices?.enumerateDevices?.();
  if (devices) {
      const video = [];
      const audio = [];
      for (const device of devices) {
          switch (device.kind) {
              case 'videoinput':
                  video.push(device);
                  break;
              case 'audioinput':
                  audio.push(device);
                  break;
          }
      }
      return { video, audio };
  } else {
      throw new Error('No support for multimedia devices.');
  }
};

export function ReaderModal({isOpen, closeModal}: ModalProps) {

    const [data, setData] = useState("")
    const [error, setError] = useState(null)
    const [devices, setDevices]: any = useState({
      video: [],
      audio: []
    });
    const [camera, setCamera] = useState("")
    const { location } = useParams()

    useEffect(() => {
        const promise = listDevices();  // <--- lists available input audio and input video devices
        promise.then((devices) => setDevices(devices)).catch((e) => setError(e));
    }, []);

    useEffect(() => {
      if (devices.video.length > 0) {
        setCamera(devices.video[0].deviceId)
      }
    }, [devices.video])

    const navigate = useNavigate()

  return (
    <>
      <Modal opened={isOpen} onClose={closeModal} title="Scan QR Code" style={{width: '100%'}}>
        <Stack>
          {!error &&           <Select
            value={camera} onChange={(val) => {if (val) {
              setCamera(val)
            }}}
            label="Camera"
            placeholder=""
            data={devices.video.map((device: MediaDeviceInfo) => {return {value: device.deviceId, label: device.label}})}
          />}
          {data && <Center>URL: {data}</Center>}
          <QrScanner
              scanDelay={5000}
              onDecode={async (data) => {
                if (data.toLowerCase().includes('http')) {
                  let cutoff = data.toLowerCase().split('.net/')[0].length + 5
                  setData(data)
                  axios.get(`${process.env.REACT_APP_API_URL}/v1/intercard/cardFromURL/${data.substring(cutoff)}`).then((res: any) => {
                    navigate(`/paytab/redeem`, {state: {card: res.data.data.url, location: location}})
                  })
                } else {
                  navigate(`/paytab/redeem`, {state: {card: data, location: location}})
                }
              }}
              onError={(error) => {
                notifications.show({
                  autoClose: 15000,
                  title: "Scan Failed!",
                  message: `Please try again or call Ext.8000! \nData: ${error}`,
                  color: 'red',
                  icon: <IconBug />
                });
              }}
              viewFinderBorder={175}
              constraints={{deviceId: camera, facingMode: 'environment'}}
          />
        </Stack>
      </Modal>
    </>
  );
}