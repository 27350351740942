import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { loyaltyReducer } from "./reducers/loyalty";
import { encryptTransform } from "redux-persist-transform-encrypt";

const persistConfig = {
  key: 'root',
  storage,
  transforms: [
    encryptTransform({
      secretKey: 'BMALoyalty2023',
      onError: function (error: any) {
        console.log('Encryption Error')
      },
    }),
  ],
}

const persistedReducer = persistReducer(persistConfig, combineReducers({
  loyalty: loyaltyReducer
}))


export const store = configureStore({
  reducer: {
    persistedReducer
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['persist/PERSIST'],
        // Ignore these field paths in all actions
        ignoredActionPaths: [],
        // Ignore these paths in the state
        ignoredPaths: ['payload','register'],
      },
    }),
});

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch