import { createStyles, Title, Text, Container, rem, LoadingOverlay } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFBTUsers } from "../../hooks/loyalty/useFBTUsers";
import { UserTable } from "../../components/loyalty/userTable";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { tab } from "@testing-library/user-event/dist/tab";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: rem(120),
    paddingBottom: rem(80),

    [theme.fn.smallerThan("sm")]: {
      paddingTop: rem(80),
      paddingBottom: rem(60),
    },
  },

  inner: {
    position: "relative",
    zIndex: 1,
    paddingBottom: rem(25),
  },

  title: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: rem(40),
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
      textAlign: "left",
    },
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },

  description: {
    textAlign: "center",

    [theme.fn.smallerThan("xs")]: {
      textAlign: "left",
      fontSize: theme.fontSizes.md,
    },
  },
}));

const locations: any = {
  1: "Web",
  9172: "HeadPinz Fort Myers",
  3141: "Bowland Beacon",
  4077: "Bowland Port Charlotte",
  11542: "Fast Trax Entertainment",
  3148: "HeadPinz Naples",
};

export function FBT() {
  const { classes } = useStyles();

  const { phone, expireTime } = useAppSelector((state) => state.persistedReducer.loyalty);

  const { isLoading, isError, users } = useFBTUsers(phone.substring(1));

  const navigate = useNavigate();

  useEffect(() => {
    if (phone === "" || expireTime < new Date().getTime()) {
      navigate("/loyalty/transfer/start");
    }
  }, [phone, expireTime]);

  const [tableUsers, setUsers] = useState<any[]>([]);

  useEffect(() => {
    if (!isLoading && !isError) {
      let mappedUsers: any[] = [];

      for (const [key, value] of Object.entries(users)) {
        let id: string = key;
        let user: any = value;

        mappedUsers.push({
          name: `${user.FirstName} ${user.LastName.substr(0, 1)}`,
          phone: phone,
          location: user.IDCenter in locations ? `${locations[user.IDCenter]}` : "Other",
          id: id,
          account: user,
          points: user.Points,
        });

        console.log(mappedUsers);
      }

      setUsers(mappedUsers);
    }
  }, [users, isLoading, isError]);

  return (
    <>
      <LoadingOverlay visible={isLoading} overlayBlur={2} loaderProps={{ size: "xl" }} />
      <Container className={classes.wrapper} size={1400}>
        <div className={classes.inner}>
          <Title className={classes.title}>
            Select{" "}
            <Text component="span" className={classes.highlight} inherit>
              Accounts
            </Text>{" "}
            to Transfer
          </Title>
          <Text size="lg" className={classes.description}>
            {`Below is a list of all accounts from Bowland & HeadPinz center with your phone number.\n
               Please select which ones you would like to transfer to rewards.`}
          </Text>
          <Container>{!isLoading && tableUsers && <UserTable data={tableUsers} />}</Container>
        </div>
      </Container>
    </>
  );
}
