import {
  createStyles,
  Title,
  Text,
  Container,
  rem,
  Grid,
  LoadingOverlay,
  em,
  Divider,
  Space,
  Modal,
  Button,
  Group,
  List,
  Stack,
} from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import { TabCard } from "../loyalty/tabCard";
import { useEffect, useState } from "react";
import { Order } from "square";
import { useClaimOrders } from "../../hooks/loyalty/useClaimOrders";
import axios from "axios";
import { notifications } from "@mantine/notifications";
import { IconExclamationCircle } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";
import { useCustomer } from "../../hooks/loyalty/useCustomer";

const lookupList: any = {
  "8V12T7K6YNJG3": "Bowland Beacon",
  "2QSCA9S3332FC": "HeadPinz Fort Myers",
  PPTR5G2N0QXF7: "HeadPinz Naples",
  AP9CY28EAR7HJ: "Bowland Port Charlotte",
  "6MZJFTGAYD7TC": "Food Truck",
};

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: rem(120),
    paddingBottom: rem(80),

    [theme.fn.smallerThan("sm")]: {
      paddingTop: rem(80),
      paddingBottom: rem(60),
    },
  },

  inner: {
    position: "relative",
    zIndex: 1,
    paddingBottom: rem(25),
  },

  title: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: rem(40),
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },
}));

export function ClaimOrders() {
  const { classes } = useStyles();

  const { state } = useLocation();

  const { isLoading, isError, orders } = useClaimOrders(state.account.customerId, state.reward);

  const [loading, setLoading] = useState(false);

  const small = useMediaQuery(`(max-width: 480px)`);

  return (
    <>
      <LoadingOverlay visible={isLoading || loading} overlayBlur={2} loaderProps={{ size: "xl" }} />
      {!isError && orders && orders.length > 0 ? (
        <>
          <Title className={classes.title}>
            Select your tab below and your reward will automatically be applied.
          </Title>
          <Space h={"xl"} />
          <Title className={classes.title}>
            Reward:{" "}
            <Text component="span" className={classes.highlight} inherit>
              {state.reward.name}
            </Text>
          </Title>
        </>
      ) : (
        <>
          <Title className={classes.title}>No tabs found.</Title>
          <Space h={"xl"} />
          <Title className={classes.title}>
            Ask your server to start one or add your loyalty account to a tab.
          </Title>
        </>
      )}
      <Grid gutter="xl" grow={small} justify="center">
        {!isError &&
          orders &&
          orders.map((order: Order) => {
            return (
              <Grid.Col key={order.id} span="content" sx={{ paddingBottom: rem(25) }}>
                <TabCard
                  title={lookupList[order.locationId]}
                  total={order.netAmountDueMoney?.amount}
                  order={order}
                  loadHandler={(val: boolean) => setLoading(val)}
                />
              </Grid.Col>
            );
          })}
      </Grid>
    </>
  );
}
