import { createStyles, Title, Text, Container, rem, Grid, LoadingOverlay } from "@mantine/core";
import { useLocation } from "react-router-dom";
import { useOrders } from "../../hooks/paytab/useOrders";
import { TabCard } from "../../components/paytab/tabCard";
import { RedeemModal } from "../../components/paytab/redeemModal";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { Order } from "square";
import { useCardBalance } from "../../hooks/paytab/useCardBalance";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: rem(120),
    paddingBottom: rem(80),

    [theme.fn.smallerThan("sm")]: {
      paddingTop: rem(80),
      paddingBottom: rem(60),
    },
  },

  inner: {
    position: "relative",
    zIndex: 1,
    paddingBottom: rem(25),
  },

  title: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: rem(40),
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
      textAlign: "left",
    },
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },
}));

export function Tabs() {
  const { classes } = useStyles();

  const { state } = useLocation();

  const { isLoading, isError, orders } = useOrders(state.location);

  const [openedRedeem, redeem] = useDisclosure(false);

  const [order, setOrder] = useState<Order>();

  const { isLoading: cardLoading, isError: cardError, balance } = useCardBalance(state.card);

  return (
    <>
      <LoadingOverlay visible={isLoading} overlayBlur={2} loaderProps={{ size: "xl" }} />
      <Container className={classes.wrapper} size={1400}>
        <div className={classes.inner}>
          <Title className={classes.title}>
            Select a{" "}
            <Text component="span" className={classes.highlight} inherit>
              Square
            </Text>{" "}
            Tab
          </Title>
        </div>
        <Grid gutter="xl">
          {!isError &&
            orders &&
            orders.map((order: Order) => {
              return (
                <Grid.Col
                  key={order.id}
                  span={4}
                  sx={{ paddingBottom: rem(75) }}
                  onClick={() => {
                    setOrder(order);
                    redeem.open();
                  }}
                >
                  <TabCard title={order.ticketName} total={order.netAmountDueMoney?.amount} />
                </Grid.Col>
              );
            })}
        </Grid>
      </Container>
      {!cardLoading && !isError && !cardError && order && (
        <RedeemModal
          isOpen={openedRedeem}
          closeModal={() => {
            redeem.close();
            setOrder(undefined);
          }}
          order={order}
          cardNumber={state.card}
          balance={balance}
          location={state.location}
        />
      )}
    </>
  );
}
