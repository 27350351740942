import { useQuery } from "react-query"
import axios from "axios"

export const useRedeemed = (locationID: string | undefined) => {

  const searchRedeemed = () => axios.get(`${process.env.REACT_APP_API_URL}/v1/${locationID}/getRedeemed`, {})
  
  const { isLoading, isError, data: redeemed } = useQuery({
    queryKey: ['redeemed'],
    queryFn: () => searchRedeemed(),
    enabled: true,
    staleTime: 1000,
    refetchInterval: 5000,
    select(data) {
          // example items: 'Boneless Wings', 'Water Moccasin', 'Kids Grilled Cheese'
          return data.data.data
    }
  })

  return {  isLoading, isError, redeemed }
}