import {
  Container, Text} from '@mantine/core';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function Home() {

  const navigate = useNavigate()

  useEffect(() => {
    navigate('/loyalty/account/login')
  })

return (
  <Container fluid py="sm">
  </Container>
);
}