import { useQuery } from "react-query"
import axios from "axios"

export const useFBTUsers = (phoneNumber: string) => {

  const getUsers = () => axios.get(`${process.env.REACT_APP_API_URL}/v1/conqx/getFBT/${phoneNumber}`).then((res) => res.data)

  const { isLoading, isError, data: users } = useQuery({
    queryKey: ['users'],
    queryFn: () => getUsers(),
    enabled: true,
    staleTime: 30000,
    refetchInterval: 30000,
    select(data) {
      return data.data
    }
  })

  return { isLoading, isError, users }
}
