import { Modal } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconChristmasTreeOff, IconDeer } from '@tabler/icons-react';
import axios from 'axios';
import { useEffect } from 'react';
import CardSwipe from 'react-card-swipe'
import { redirect, useNavigate } from 'react-router-dom';

interface ModalProps {
  isOpen: boolean
  closeModal: any
  order: any;
}

async function submitHoliday(orderID: string, paymentAmount: number, cardNumber: string, location: string, item: string) {

  let body = {
    order: orderID,
    paymentAmount: paymentAmount,
    card: cardNumber,
    item: item
  }

  return await axios.post(`${process.env.REACT_APP_API_URL}/v1/${location}/submitHoliday`, body, {headers: {
    'Content-Type': 'application/json'
  }}).then((res: any) => {
    console.log(res)

    if (res.data.success) {
        
      notifications.show({
        autoClose: 10000,
        title: "Payment Successful!",
        message: 'Happy holidays and the card is now loaded.',
        color: 'green',
        icon: <IconDeer />
      });
      
      return true

    } else {

      notifications.show({
        autoClose: 10000,
        title: "Payment Failed!",
        message: 'Please try again or call Ext.8000!',
        color: 'red',
        icon: <IconChristmasTreeOff />
      });

      return false
    }
  }).catch((error: any) => {
    notifications.show({
      autoClose: 10000,
      title: "Payment Failed!",
      message: 'Please try again or call Ext.8000!',
      color: 'red',
      icon: <IconChristmasTreeOff />
    });
    return false
  })
}

export function SwipeModal({isOpen, closeModal, order}: ModalProps) {

    const navigate = useNavigate()

    const handleSwipeSuccess = async (swipeData: any) => {
      // navigate(`/paytab/redeem`, {state: {card: swipeData.line2.split('=')[1], location: location}})

      const cardValue = swipeData.line2.split('=')[1];
      console.log(order)
      const updatedOrder = { ...order, card: cardValue };
      console.log(updatedOrder);
      if (await submitHoliday(updatedOrder.id, updatedOrder.grossPrice, updatedOrder.card, updatedOrder.locationId, updatedOrder.lineItemID)) {
        navigate(`/giftcard/complete`, {state: {card: swipeData.line2.split('=')[1], location: order.locationId, success: true}});
      } else {
        navigate(`/giftcard/complete`, {state: {card: swipeData.line2.split('=')[1], location: order.locationId, success: false}});
      }
      // hit api here

      //navigate(`/giftcard/complete`, {state: {card: swipeData.line2.split('=')[1], location: order.locationId}});
    }

    useEffect(() => {
      CardSwipe.init({
        success: handleSwipeSuccess,
        debug: false,
        interdigitTimeout: 1000
      })
    }, [isOpen])

  return (
    <>
      <Modal opened={isOpen} onClose={closeModal} title="Swipe Card">
      </Modal>
    </>
  );
}