import { ColorScheme, ColorSchemeProvider, MantineProvider, Paper } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import "./App.css";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import { Home } from "./pages/Home";
import { NoMatch } from "./pages/NoMatch";
import { ModalControl } from "./pages/paytab/SquareRedeem";
import { ModalsProvider } from "@mantine/modals";
import { Tabs } from "./pages/paytab/Tabs";
import { PayedCards } from "./pages/giftcards/PayedCards";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Notifications } from "@mantine/notifications";
import { LoyaltyTransferLanding } from "./pages/loyalty/LoyaltyTransfer";
import { FBT } from "./pages/loyalty/FBT";
import { Provider } from "react-redux";
import { persistor, store } from "./lib/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { LoyaltyLogin } from "./pages/loyalty/LoyaltyLogin";
import { AccountReview } from "./pages/loyalty/LoyaltyAccount";
import { LoyaltyHistory } from "./pages/loyalty/LoyaltyHistory";
import { Container as ModalContainer } from "react-modal-promise";
import { ClaimHolder } from "./pages/loyalty/ClaimHolder";
import { CompletedActivation } from "./pages/giftcards/CompletedActivation";
import { WifiLoyalty } from "./pages/loyalty/WifiLoyalty";
import LoyaltySignup from "./pages/loyalty/LoyaltySignUp";
import LoyaltyCreate from "./pages/loyalty/LoyaltyCreate";
import { LoyaltyRewards } from "./pages/loyalty/LoyaltyRewards";
import { LoyaltyRewardsSlim } from "./pages/loyalty/LoyaltyRewardsSlim";

const defaultRoutes = [
  { path: "*", element: <NoMatch /> },
  { path: "/", element: <Home />, index: true },
  {
    path: "loyalty",
    children: [
      { path: "infoSlim", element: <LoyaltyRewardsSlim /> },
      { path: "info", element: <LoyaltyRewards /> },
      { path: "account/login", element: <LoyaltyLogin /> },
      { path: "account/claim", element: <ClaimHolder /> },
      { path: "account/review", element: <AccountReview /> },
      { path: "account/history", element: <LoyaltyHistory /> },
      { path: "account/signup", element: <LoyaltySignup /> },
      { path: "account/create", element: <LoyaltyCreate /> },
      { path: "transfer/start", element: <LoyaltyTransferLanding /> },
      { path: "transfer/accounts", element: <FBT /> },
    ],
  },
  {
    path: "paytab",
    children: [
      { path: ":location", children: [{ path: "reader", element: <ModalControl /> }] },
      { path: "redeem", element: <Tabs /> },
    ],
  },
  {
    path: "giftcard",
    children: [
      { path: ":location", children: [{ path: "redeem", element: <PayedCards /> }] },
      { path: "complete", element: <CompletedActivation /> },
    ],
  },
  {
    path: "wifi",
    element: <WifiLoyalty />,
  },
];

const router = createBrowserRouter([
  { path: "/", element: <Layout />, children: [...defaultRoutes] },
]);

export default function App() {
  return <RouterProvider router={router} />;
}

const queryClient = new QueryClient({});

function Layout() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: "mantine-color-scheme",
    defaultValue: "dark",
  });

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <MantineProvider
              theme={{
                colorScheme,
                colors: {
                  brand: [
                    "#FFFFFF",
                    "#E3F2FD",
                    "#BBDEFB",
                    "#90CAF9",
                    "#83b4fe",
                    "#42A5F5",
                    "#83b4fe",
                    "#1E88E5",
                    "#1976D2",
                    "#1565C0",
                  ],
                },
                primaryColor: "brand",
                primaryShade: 8,
              }}
              withGlobalStyles
              withNormalizeCSS
            >
              <Notifications position="top-center" zIndex={2077} />
              <ModalsProvider>
                <ModalContainer />
                <Paper sx={{ background: `rgba(0,0,0,0)`, minHeight: "100vh", height: "auto" }}>
                  <Outlet />
                  <ReactQueryDevtools initialIsOpen={false} />
                </Paper>
              </ModalsProvider>
            </MantineProvider>
          </ColorSchemeProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}
