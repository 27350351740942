import { createStyles, rem, Group, Text, Stack, Divider } from '@mantine/core';
import { Order, OrderLineItem } from 'square';

const useStyles = createStyles((theme) => ({

  imageSection: {
    padding: theme.spacing.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  label: {
    marginBottom: theme.spacing.xs,
    lineHeight: 1,
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    letterSpacing: rem(-0.25),
    textTransform: 'uppercase',
  },

  section: {
    padding: theme.spacing.md,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
  },

  icon: {
    marginRight: rem(5),
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
  },

  itemList: {
    width: 'fit-content', 
    margin: 'auto', 
    borderBottom: `${rem(1)} solid`,
    paddingBottom: `${theme.spacing.xs}`
  }

}));

interface TabProps {
  order: Order
  appliedMoney: number
  balance: number
  orderTotal: number
}

interface LineItemProps extends OrderLineItem {
  total: any
}

function LineItem({quantity, name, total}: LineItemProps) {
  return (
    <Group>
      <Text>{quantity} x {name}</Text>
      <Text fw={500}>{Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(total*0.01)}</Text>
    </Group>
  )
}

export function TabInfo({order, orderTotal, appliedMoney, balance}: TabProps) {

  const { classes } = useStyles();

  return (
    <>
      <Text fz="xl" c="dimmed" className={classes.label}>
        Tab Total - {order.ticketName}
      </Text>
      <Stack align='center'spacing="xs">
        <Stack align="center" className={classes.itemList}>
          {order.lineItems?.map((item: OrderLineItem) => {
            return <LineItem key={item.uid} quantity={item.quantity} name={item.name} total={item.totalMoney?.amount}/>
          })}
          {appliedMoney > 0 &&
          <LineItem key='Discount' quantity={'1'} name={'Passport To Fun'} total={-appliedMoney*100}/>}
        </Stack>
        <Group>
          <Text>Total Remaining:</Text>
          <Text fw={500}> {orderTotal !== 0 ? 
            Intl.NumberFormat('en-US', {style: 'currency', currency: order.netAmountDueMoney?.currency}).format(orderTotal - (appliedMoney)): '0.00'}
          </Text>
        </Group>
        {appliedMoney > 0 &&
        <Group>
          <Text>Gift Card Remaining:</Text>
          <Text fw={500}> {orderTotal !== 0 ? 
            Intl.NumberFormat('en-US', {style: 'currency', currency: order.netAmountDueMoney?.currency}).format(balance-appliedMoney) 
            : '0.00'}
          </Text>
        </Group>
        }
      </Stack>
      <Divider my="sm" />
    </>
  );
}