import { createStyles, Container, rem, Space } from "@mantine/core";
import { ClaimOrders } from "../../components/loyalty/tabsSelect";
import { ClaimType } from "../../components/loyalty/typeSelect";
import { useState } from "react";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: rem(120),
    paddingBottom: rem(80),

    [theme.fn.smallerThan("sm")]: {
      paddingTop: rem(80),
      paddingBottom: rem(60),
    },
  },

  inner: {
    position: "relative",
    zIndex: 1,
    paddingBottom: rem(25),
  },

  title: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: rem(40),
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
    },
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },
}));

export function ClaimHolder() {
  const { classes } = useStyles();

  const [selected, setSelected] = useState(false);

  return (
    <>
      <Container className={classes.wrapper} size={1400}>
        <div className={classes.inner}>
          {selected ? <ClaimOrders /> : <ClaimType handler={() => setSelected(true)} />}
        </div>
        <Space h={"xl"} />
      </Container>
    </>
  );
}
