import { useQuery } from "react-query"
import axios from "axios"
import { LoyaltyProgramRewardTier } from "square"

export const useRewards = () => {

  const getRewards = async (): Promise<any> => await new Promise(async (resolve, reject) => {
    setTimeout(() => {
      resolve (axios.get(`${process.env.REACT_APP_API_URL}/v1/square/loyalty/rewards`).then((res) => res.data))
    }, 0)

  })

  const { isLoading, isError, data: rewards }: {data: LoyaltyProgramRewardTier[] | undefined, isLoading: boolean, isError: boolean} = useQuery({
    queryKey: ['rewards'],
    queryFn: () => getRewards(),
    enabled: true,
    staleTime: 5000,
    refetchInterval: 5000,
    select(data) {
      return data.data
    }
  })

  return { isLoading, isError, rewards }
}
