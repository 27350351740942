import {
  Stack,
  Group,
  Container,
  Image,
  Title,
  ActionIcon,
  rem,
  Space,
  Center,
  createStyles,
  keyframes,
} from "@mantine/core";
import { IconArrowDown } from "@tabler/icons-react";
import { useMediaQuery, useScrollIntoView } from "@mantine/hooks";
import LoyaltySignupForm from "../../components/loyalty/signUpForm";

const bounce = keyframes({
  to: { transform: "scale(1.2)" },
});

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: rem(120),

    [theme.fn.smallerThan("sm")]: {
      paddingTop: rem(80),
    },
  },

  wrapperBottom: {
    [theme.fn.smallerThan("sm")]: {
      paddingTop: rem(140),
      paddingBottom: rem(60),
    },
  },

  label: {
    textAlign: "center",
    fontWeight: 800,
  },

  animateArrow: {
    animation: `${bounce} 1s infinite alternate`,
  },

  title: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: rem(40),
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },
}));

export default function LoyaltyCreate() {
  const { classes } = useStyles();

  const mobile = useMediaQuery("(max-width: 48em)");

  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 60,
  });

  return (
    <>
      <Container className={classes.wrapper}>
        <Stack justify="space-around" align="stretch" spacing="xl">
          <Group position="center">
            <Image src="../../HPLogo-Small.png" width="auto" fit="contain"></Image>
            <Image src="../../HeadPinz Rewards.png" width="auto" fit="contain"></Image>
          </Group>
          <Title className={classes.title}>Create a rewards account below to get started.</Title>
          <Title order={3} style={{ textAlign: "center" }}>
            Be the first to hear about new specials and promotions!
          </Title>
          {mobile && (
            <Center className={classes.animateArrow}>
              <ActionIcon
                variant="light"
                size="xl"
                radius="xl"
                aria-label="Settings"
                onClick={() =>
                  scrollIntoView({
                    alignment: "end",
                  })
                }
              >
                <IconArrowDown style={{ width: "70%", height: "70%" }} stroke={1.5} />
              </ActionIcon>
            </Center>
          )}
        </Stack>
      </Container>
      <Space h="xl" />
      <Container className={classes.wrapperBottom} ref={targetRef}>
        <LoyaltySignupForm redirect="/loyalty/account/login" />
      </Container>
    </>
  );
}
