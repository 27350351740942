import {
  createStyles,
  Container,
  rem,
  Grid,
  Group,
  Stack,
  Image,
  Title,
  Badge,
  Space,
  List,
} from "@mantine/core";
import { useRewards } from "../../hooks/loyalty/useRewards";
import { useEffect, useState } from "react";
import { LoyaltyProgramRewardTier } from "square";
import { useMediaQuery } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: rem(120),
    paddingBottom: rem(80),

    [theme.fn.smallerThan("sm")]: {
      paddingTop: rem(80),
      paddingBottom: rem(60),
    },
  },

  inner: {
    position: "relative",
    zIndex: 1,
    paddingBottom: rem(25),
  },

  title: {
    textAlign: "center",
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
      textAlign: "left",
    },
  },
}));

export function LoyaltyRewards() {
  const { classes } = useStyles();

  const { isLoading, isError, rewards: unsortedRewards } = useRewards();

  const [rewards, setRewards]: any = useState([]);

  const mobile = useMediaQuery(`(max-width: 60em)`);

  function sortRewards(a: LoyaltyProgramRewardTier, b: LoyaltyProgramRewardTier) {
    if (a.points > b.points) {
      return 1;
    } else if (a.points < b.points) {
      return -1;
    }
    return 0;
  }

  useEffect(() => {
    let rewardsList = unsortedRewards;

    if (rewardsList && unsortedRewards) {
      rewardsList.sort(sortRewards);

      console.log(rewardsList);

      setRewards(rewardsList);
    }
  }, [unsortedRewards]);

  return (
    <>
      <Container className={classes.wrapper}>
        <Stack justify="space-around" align="stretch" spacing="xl">
          <Group position="center">
            <Image src="../../HPLogo-Small.png" width="auto" fit="contain"></Image>
            <Image src="../../HeadPinz Rewards.png" width="auto" fit="contain"></Image>
          </Group>
          <Space h={"xl"} />
          <Grid justify="center" align="center" columns={mobile ? 1 : 2}>
            <Grid.Col span={1}>
              <Stack
                align="flex-start"
                spacing="xl"
                style={{
                  border: "5px solid",
                  borderRadius: "15px",
                  padding: rem(10),
                  borderColor: "white",
                }}
              >
                <Space />
                <List
                  spacing="xs"
                  size="sm"
                  center
                  icon={<Image src="../../Pin.png" width="auto" fit="contain"></Image>}
                >
                  {rewards.map((reward: LoyaltyProgramRewardTier) => {
                    return (
                      <List.Item>
                        <Stack align="flex-start" spacing="xs">
                          <Badge
                            size="xl"
                            variant="gradient"
                            gradient={{ from: "blue", to: "indigo" }}
                          >
                            {reward.points} pins
                          </Badge>
                          <Title order={3} color="white">
                            {reward.name}
                          </Title>
                        </Stack>
                      </List.Item>
                    );
                  })}
                </List>
                <Space />
              </Stack>
            </Grid.Col>
            <Grid.Col span={1}>
              <Stack align="center" spacing="xl">
                <Image src="../../plusRewards.png" width="auto" fit="contain"></Image>
                <List
                  spacing="xs"
                  size="sm"
                  center
                  icon={<Image src="../../bowling.png" width="auto" fit="contain"></Image>}
                >
                  <List.Item>
                    <Title order={2} color="white">
                      Exclusive Discounts
                    </Title>
                  </List.Item>
                  <List.Item>
                    <Title order={2} color="white">
                      Special Promotions
                    </Title>
                  </List.Item>
                  <List.Item>
                    <Title order={2} color="white">
                      Birthday Rewards
                    </Title>
                  </List.Item>
                </List>
              </Stack>
            </Grid.Col>
          </Grid>
        </Stack>
      </Container>
    </>
  );
}
