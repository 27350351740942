import { Card, Image, Text, Badge, Button, Group, useMantineTheme, Divider, rem, Space } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useLocation } from 'react-router-dom';


function formatDate(date: any) {
    return new Date(date).toLocaleDateString('en-us', { year:"numeric", month:"numeric", day:"numeric"}) 
}

function getNameById(id: any) {
    const data = [
        { "id": "AP9CY28EAR7HJ", "name": "Nemos @ Bowland Port Charlotte" },
        { "id": "LYXDAWY6EVH4Y", "name": "Nemo's Brickyard Bistro @ Fast Trax Fort Myers" },
        { "id": "PPTR5G2N0QXF7", "name": "Nemo's @ HeadPinz Naples" },
        { "id": "TXBSQN0FEKQ11", "name": "Nemo's @ HeadPinz Fort Myers" },
        { "id": "B8B6XFVQNHVCG", "name": "Nemo's @ HeadPinz Cape Coral" },
        { "id": "2QSCA9S3332FC", "name": "Nemo's @ Bowland Cape Coral" },
        { "id": "8V12T7K6YNJG3", "name": "Nemo's @ Bowland Beacon" },
        { "id": "6MZJFTGAYD7TC", "name": "Food Truck" }
    ];

    const match = data.find(item => item.id === id);
    return match ? match.name : "Name not found";
}

export function EventCard({events}: any) {
//   const theme = useMantineTheme();

const small = useMediaQuery(`(max-width: 75em)`);

  return (
    <>
        {events
            .filter((item: { type: string; }) => item.type !== 'REDEEM_REWARD')
            .map((item: any) => {
                return (
                    <div key={item.id} style={{ width: '96%', margin: 'auto', marginTop: 10 }}>
                        <Card color="blue "shadow="sm" padding="sm" withBorder>
                            <Group position="apart" style={{ marginBottom: 5, marginTop: 5 }}>
                                <Text weight={700} size="xl">
                                    {item.type === "ACCUMULATE_POINTS" ? "Earned Points" : item.type === "CREATE_REWARD" ? "Reward Redeemed" : item.type}
                                </Text>
                                
                                <Text weight={700} size="xl" color={item.type === "ACCUMULATE_POINTS" ? (item.accumulatePoints.points < 0 ? "red" : "green")  : (item.createReward.points < 0 ? "red" : "green")} variant="light">
                                    {item.type === "ACCUMULATE_POINTS" ? "+" + item.accumulatePoints.points : item.createReward.points}
                                </Text>
                            </Group>
               
                            <Text sx={small ? {textAlign: 'left'} : {}} color="dimmed" size="xs" transform="uppercase" weight={700}>
                                {formatDate(item.createdAt)}
                                {item.type === "ACCUMULATE_POINTS" ? " • " + getNameById(item.locationId) : ' '}
                            </Text>
                        </Card>
                        <Space h={'sm'}/>
                        <Divider sx={{paddingBottom: rem(15)}}/>
                    </div>
                );
            })}
    </>
    

  );
}