import { useQuery } from "react-query";
import axios from "axios";
import { LoyaltyAccount } from "square";

export const useLoyalty = (phoneNumber: string) => {
  const getLoyalty = () =>
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/square/loyalty/phone/${phoneNumber}`)
      .then((res) => res.data);

  const {
    isLoading,
    isError,
    data: loyalty,
  }: { isLoading: boolean; isError: boolean; data: LoyaltyAccount | undefined } = useQuery({
    queryKey: ["loyalty"],
    queryFn: () => getLoyalty(),
    enabled: true,
    staleTime: 5000,
    refetchInterval: 5000,
    select(data) {
      if (data.data) {
        return data.data[0];
      }

      return undefined;
    },
  });

  return { isLoading, isError, loyalty };
};
