import { useEffect } from "react";
import axios from "axios";
import { Center, Container, Title, createStyles, rem, Text, Stack } from "@mantine/core";
import { BarLoader } from "react-spinners";

const useStyles = createStyles((theme) => ({
  wrapper: {
    margin: "auto",

    [theme.fn.smallerThan("sm")]: {
      paddingTop: rem(80),
      paddingBottom: rem(60),
    },
  },

  title: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: rem(40),
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
      textAlign: "left",
    },
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },
}));

export function WifiLoyalty() {
  const { classes, theme } = useStyles();

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const guestToken = queryParameters.get("guest_token");

    console.log(process.env.REACT_APP_API_URL);

    axios
      .post(`${process.env.REACT_APP_API_URL}/v1/square/customers/wifiCreate/${guestToken}`)
      .then(async (res) => {
        window.location.assign("https://headpinz.com/wifi");
      })
      .catch(async (res) => {
        window.location.assign("https://headpinz.com/rewards");
      });
  }, []);

  return (
    <Container className={classes.wrapper} style={{ height: "100%" }}>
      <Center style={{ height: "100%" }}>
        <Stack align="center">
          <Title className={classes.title}>
            Connecting to{" "}
            <Text component="span" className={classes.highlight} inherit>
              HeadPinz
            </Text>{" "}
            WiFi
          </Title>
          <BarLoader
            color={theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6]}
          />
        </Stack>
      </Center>
    </Container>
  );
}
