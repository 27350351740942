import { createStyles, Title, Text, Container, rem, Group, InputBase, Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import axios from "axios";
import { useState } from "react";
import { IMaskInput } from "react-imask";
import { parsePhoneNumber } from "react-phone-number-input/input";
import { PinModal } from "../../components/loyalty/pinInput";
import { useAppSelector } from "../../lib/redux/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { SupportModal } from "../../components/loyalty/supportModal";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: rem(120),
    paddingBottom: rem(80),

    [theme.fn.smallerThan("sm")]: {
      paddingTop: rem(80),
      paddingBottom: rem(60),
    },
  },

  inner: {
    position: "relative",
    zIndex: 1,
  },

  title: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: rem(40),
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
      textAlign: "left",
    },
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },

  description: {
    textAlign: "center",

    [theme.fn.smallerThan("xs")]: {
      textAlign: "left",
      fontSize: theme.fontSizes.md,
    },
  },

  controls: {
    marginTop: theme.spacing.lg,
    display: "flex",
    justifyContent: "center",

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },

  control: {
    "&:not(:first-of-type)": {
      marginLeft: theme.spacing.md,
    },

    [theme.fn.smallerThan("xs")]: {
      height: rem(42),
      fontSize: theme.fontSizes.md,

      "&:not(:first-of-type)": {
        marginTop: theme.spacing.md,
        marginLeft: 0,
      },
    },
  },
}));

export function LoyaltyLogin() {
  const { classes } = useStyles();

  const { phone, expireTime } = useAppSelector((state) => state.persistedReducer.loyalty);

  const [inputPhone, setInput] = useState<string>("");

  const navigate = useNavigate();

  const { state } = useLocation();

  const form = useForm({
    initialValues: {
      phone: "",
    },

    validate: {
      phone: (value) => (parsePhoneNumber(value)?.isValid() ? null : "Invalid Phone Number"),
    },
  });

  const [opened, pinModal] = useDisclosure(false);
  const [supportOpen, supportModal] = useDisclosure(state && state.support ? true : false);

  const handleSubmit = async (phoneNumber: string) => {
    if (expireTime > new Date().getTime() && phone === phoneNumber) {
      navigate("/loyalty/account/review");
      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/otp`,
        { phoneNumber: phoneNumber },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setInput(phoneNumber);
        }
      });

    pinModal.open();
  };

  return (
    <>
      <Container className={classes.wrapper} size={1400}>
        <div className={classes.inner}>
          <Title className={classes.title}>
            Welcome to{" "}
            <Text component="span" className={classes.highlight} inherit>
              HeadPinz
            </Text>{" "}
            Rewards
          </Title>

          <Container p={0} size={600}>
            <Text size="lg" color="dimmed" className={classes.description}>
              Please enter a phone number to login!
            </Text>
          </Container>
          <div className={classes.controls}>
            <form
              onSubmit={form.onSubmit((values) =>
                handleSubmit(values.phone.replace(/[^0-9.]/g, ""))
              )}
            >
              <Group>
                <InputBase
                  inputMode="numeric"
                  size="lg"
                  component={IMaskInput}
                  mask="+1 (000) 000-0000"
                  {...form.getInputProps("phone")}
                />
                <Button type="submit" radius="xl" size="lg">
                  Login
                </Button>
              </Group>
            </form>
          </div>
        </div>
      </Container>
      <PinModal
        isOpen={opened}
        closeModal={() => pinModal.close()}
        phoneNumber={inputPhone}
        route="/loyalty/account/review"
      />
      <SupportModal isOpen={supportOpen} closeModal={() => supportModal.close()} phone={phone} />
    </>
  );
}
