import {
  createStyles,
  Title,
  Text,
  Container,
  rem,
  LoadingOverlay,
  Space,
  Center,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { clearPhone } from "../../lib/redux/reducers/loyalty";
import { StatsCard } from "../../components/loyalty/userCard";
import { useLoyalty } from "../../hooks/loyalty/useLoyaltyAcc";
import axios from "axios";
import { useCustomer } from "../../hooks/loyalty/useCustomer";
import { CustCreateModal } from "../../components/loyalty/createCustModal";
import { useLoyaltyEvents } from "../../hooks/loyalty/useLoyaltyEvents";
import { IconExclamationCircle } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: rem(120),
    paddingBottom: rem(80),

    [theme.fn.smallerThan("sm")]: {
      paddingTop: rem(80),
      paddingBottom: rem(60),
    },
  },

  inner: {
    position: "relative",
    zIndex: 1,
    paddingBottom: rem(25),
  },

  title: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: rem(40),
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
      textAlign: "left",
    },
  },

  header: {
    fontWeight: 800,
    fontSize: rem(25),
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
      textAlign: "center",
    },
  },

  highlight: {
    color: theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },
}));

export function AccountReview() {
  const { classes } = useStyles();

  const { phone, expireTime } = useAppSelector((state) => state.persistedReducer.loyalty);

  const navigate = useNavigate();

  const { isLoading, isError, loyalty } = useLoyalty(phone);
  const dispatch = useAppDispatch();

  const { customer } = useCustomer(loyalty ? loyalty.customerId! : "");

  const { events } = useLoyaltyEvents(loyalty ? loyalty.id! : "");

  useEffect(() => {
    if (phone === "" || expireTime < new Date().getTime()) {
      navigate("/loyalty/account/login");
      dispatch(clearPhone());
    }
  }, [phone, expireTime]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/v1/square/customers/${phone}`).then(async (res) => {
      let userData = res.data.data;

      console.log(userData);

      if (userData.count === 0) {
        navigate("/loyalty/account/create");
      } else if (!userData[0]) {
        notifications.show({
          autoClose: 10000,
          title: "UhOh!",
          message: "Something went wrong. Please try again later.",
          color: "red",
          icon: <IconExclamationCircle />,
        });
      }
    });

    console.log(events);
  }, []);

  return (
    <>
      <LoadingOverlay visible={isLoading} overlayBlur={2} loaderProps={{ size: "xl" }} />
      {loyalty && (
        <Container className={classes.wrapper} size={1400}>
          <div className={classes.inner}>
            <Container>
              <Center>
                {customer && (
                  <Title className={classes.header}>
                    Welcome back{" "}
                    <Text component="span" className={classes.highlight} inherit>
                      {customer.givenName} {customer.familyName}
                    </Text>
                    !
                  </Title>
                )}
              </Center>
              <Space h={"xl"} />
              {!isLoading && !isError && <StatsCard account={loyalty} events={events} />}
            </Container>
          </div>
        </Container>
      )}
    </>
  );
}
